<h1 matDialogTitle *ngIf="stepIndex() === 0">
  <div class="flex flex-row items-center justify-start">
    <div>{{ 'time.pick-project' | translate }}</div>
    <div class="flex-auto"></div>
    <button mat-icon-button matDialogClose tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>
<tg-plain-stepper [(selectedIndex)]="stepIndex" class="relative h-full overflow-hidden">
  <cdk-step>
    <tg-project-list
      class="max-h-inherit"
      showNextItemIcon
      [selectedEntity]="entityData"
      (entityChange)="onSelectProject($event)"
      [suggestProjectByName]="createData?.title"
    ></tg-project-list>
  </cdk-step>
  <cdk-step>
    <app-time-tracker-calendar-create-dialog [data]="createData"></app-time-tracker-calendar-create-dialog>
  </cdk-step>
</tg-plain-stepper>

<mat-dialog-actions align="end" class="mx-8 !mb-0 flex items-center space-x-4" *ngIf="stepIndex() === 0">
  <button mat-button (click)="createProject()" *ngIf="project$canManage | async">
    {{ 'project.create' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="onSelectProject()">Next</button>
</mat-dialog-actions>

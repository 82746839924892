import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Client, ClientsService, ClientsQuery, UserSettingsQuery, Project, ProjectsQuery } from 'timeghost-api';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { distinctUntilChanged, filter, startWith, debounceTime, takeUntil, map, finalize, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, combineLatest, Subject, defer } from 'rxjs';
import { CreateClientComponent } from '../create-client/create-client.component';
import { UntypedFormControl } from '@angular/forms';
import { sortBy, flow } from 'lodash-es';
import { createRxValue, hasPermission, WithOptional } from '@app/_helpers/utils';
import { CosmosEntity } from '@app/_classes/cosmos-entity';

@Component({
  selector: 'app-client-picker-dialog',
  templateUrl: './client-picker-dialog.component.html',
  styleUrls: ['./client-picker-dialog.component.scss'],
  host: {
    class: 'search-dialog-host',
  },
})
export class ClientPickerDialogComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  searchinput: UntypedFormControl = new UntypedFormControl('');

  readonly workspace$createPermission = this.userSettingsQuery.select().pipe(
    map((user) => {
      return user.workspace.permissionSettings?.groupsCanManageClients?.find((x) => hasPermission(x.id, user));
    }),
  );
  private _selectedClient = createRxValue<Client>(null);
  readonly selectedClient$ = this._selectedClient.asObservable().pipe(distinctUntilChanged());
  get selectedClient() {
    return this._selectedClient.value;
  }
  set selectedClient(val: Client) {
    this._selectedClient.next(val);
  }

  private _isLoading = new BehaviorSubject<boolean>(true);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }
  readonly project = createRxValue<Project>(null);
  defaultClient: Client;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: ClientDialogData,
    private ref: MatDialogRef<ClientPickerDialogComponent>,
    private dialog: MatDialog,
    private clientsQuery: ClientsQuery,
    private projectsQuery: ProjectsQuery,
    private userSettingsQuery: UserSettingsQuery,
  ) {
    this.defaultClient = this.clientsQuery.getAll().find((x) => x.useAsDefault === true);
    this.selectedClient = this.data.selectedClient || this.defaultClient;
    this.project.update(this.data.projectRef);
  }

  ngOnInit() {
    this.ref.addPanelClass(['client-picker-dialog-container', 'mat-dialog-relative']);
    this.ref.updateSize('400px');
  }

  isSelected(id: string) {
    return this.selectedClient?.id === id;
  }
  data$: Observable<Client[]> = this.clientsQuery.selectAll().pipe(
    takeUntil(this.destroy$),
    map((x) => x.filter((client) => client.name && !client.useAsDefault)),
  );
  data$filtered = combineLatest([
    this.searchinput.valueChanges.pipe(startWith(''), debounceTime(150), distinctUntilChanged()),
    this.selectedClient$,
    this.project.asObservable(),
    defer(() => {
      this.isLoading = true;
      return this.data$.pipe(
        tap(() => (this.isLoading = false)),
        startWith(),
      );
    }),
    this.projectsQuery.selectAll(),
  ]).pipe(
    filter(([, , , data]) => !!data),
    map(([search, selected, prj, clients, projects]: [string, Client, Project, Client[], Project[]]) => {
      const advSearch = search && search.trim().length > 0 ? search.split(' ').filter((s) => s) : null;
      const isUnique = (cl: Client) =>
        !projects.find((x) => x.client?.id === cl.id && x.name?.toLowerCase() === prj.name?.toLowerCase());
      return flow(
        (data: (Client & { selected?: boolean })[]) =>
          selected ? data.map((x) => ({ ...x, selected: x.id === selected.id })) : data,
        (data) =>
          advSearch
            ? data.filter(
                (x) =>
                  advSearch.filter((s) => x.name.toLowerCase().indexOf(s.toLowerCase()) !== -1).length >=
                    advSearch.length || x.selected,
              )
            : data,
        (data) =>
          prj
            ? data.map((x) => {
                const exists = x.selected && !x.useAsDefault ? !isUnique(this.defaultClient) : !isUnique(x);
                return {
                  ...x,
                  disabled: exists,
                  exists,
                };
              })
            : data,
      )(clients);
    }),
  );
  selectItem(client: WithOptional<Client>) {
    if (client.disabled) return;
    const selectedClient = this.selectedClient;
    if (this.data.canToggle && !!selectedClient) {
      if (selectedClient.id === client.id) {
        this.selectedClient = this.defaultClient;
        if (this.data.closeOnRemove) {
          this.ref.close(this.selectedClient);
        }
        return;
      }
    }
    this.selectedClient = client;
    this.ref.close(client);
  }
  trackFiltered(index: number, item: Client) {
    return item?.id || index;
  }
  createDialog() {
    this.dialog
      .open(CreateClientComponent, {
        data: {
          search: this.searchinput.value,
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((x: Client) => this.selectItem(x));
  }
}

export interface ClientDialogData {
  selectedClient: Client;
  projectRef?: Project;
  canToggle: boolean;
  closeOnRemove?: boolean;
}

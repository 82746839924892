import { Directive, EventEmitter, Output, Renderer2, ElementRef, OnDestroy, OnInit, HostListener } from '@angular/core';

@Directive({
  selector: '[click.prevent]',
})
export class ClickPreventDirective {
  @Output('click.prevent') preventDefaultEvent = new EventEmitter();

  constructor() {}
  @HostListener('click', ['$event'])
  onHostElementClick(ev: Event) {
    ev.preventDefault();
    this.preventDefaultEvent?.emit?.(...arguments);
  }
}

import { ComegoTime, Project, Task, Time, Workspace } from 'timeghost-api';

export function isTask(value: any): value is Task {
  return value?.cosmosEntityName === 'tasks';
}
export function isTime(value: any): value is Time {
  return value?.cosmosEntityName === 'times';
}
export function isComegoTime(value: any): value is ComegoTime {
  return value?.cosmosEntityName === 'comego';
}
export function isProject(value: any): value is Project {
  return value?.cosmosEntityName === 'projects';
}
export function isWorkspace(value: any): value is Workspace {
  return value?.cosmosEntityName === 'workspaces';
}

import { inject } from '@angular/core';
import { IdArray, IdObject } from '@app/_classes/cosmos-entity';
import { map } from 'rxjs';
import { Project, ProjectPermissionRole, UserSettings, UserSettingsQuery, Workspace } from 'timeghost-api';
import { NoIndex } from './types';
import { userProjectTaskPerm } from './userProjectFind';
import { DEFAULT_PERMISSION_GROUPS, fromRxValue, hasPermission, hasUserInProject } from './utils';

export function isAdmin(user: UserSettings) {
  return hasPermission(DEFAULT_PERMISSION_GROUPS.Admin, user);
}
export function isSupervisor(user: UserSettings) {
  return !!user.workspace?.users?.find((d) => d['supervisors']?.find((s: IdObject) => s.id === user.id));
}

export function isSupervisorOfUser(user: UserSettings, subordinateId: string) {
  return !!user.workspace?.users?.find(
    (d) => d.id === subordinateId && d['supervisors']?.find((s: IdObject) => s.id === user.id),
  );
}
export function isSubordinate(
  user: UserSettings,
  subordinateId: string,
): { supervisorId: string; subordinateId: string } | null {
  return (
    (!!user.workspace?.users?.find(
      (d) => subordinateId === d.id && d['supervisors']?.find((s: IdObject) => s.id === user.id),
    ) && { subordinateId, supervisorId: user.id }) ||
    null
  );
}
/**
 * create a function to test if a provided userId is supervised from current user, checks users and groups for subordinates
 */
export function isProjectManagerOfUser(user: UserSettings) {
  return (userId: string) =>
    !!user.workspace?.projectPermissions?.find((d) => {
      if (!d.users?.find((u) => u.id === user.id && u.role === ProjectPermissionRole.manager)) return false;
      return (
        d.users.findIndex((u) => u.id === userId) !== -1 ||
        d.groups?.find(
          (d) => !!user.workspace.groups?.find((g) => g.id === d.id && !!g.users?.find((u) => u.id === userId)),
        )
      );
    });
}
export function createTimePermissionFilter(user: UserSettings, type: 'project' | 'workinghours' = 'workinghours') {
  const userIsAdmin = isAdmin(user);
  const userCanSupervise = isSupervisor(user);
  const userProjectManagerSupervisor = isProjectManagerOfUser(user);
  return (times: { user: { id: string } }[]) =>
    times.filter((d) => {
      const subordinate = isSubordinate(user, d.user.id);
      if (type === 'workinghours') return d.user.id === user.id || userIsAdmin || (userCanSupervise && subordinate);
      else return d.user.id === user.id || userIsAdmin || userProjectManagerSupervisor(d.user.id);
    });
}
export function createUserPermissionFilter(user: UserSettings, type: 'project' | 'workinghours' = 'workinghours') {
  const userIsAdmin = isAdmin(user);
  const userCanSupervise = isSupervisor(user);
  const userProjectManagerSupervisor = isProjectManagerOfUser(user);
  return (users: { id: string }[]) =>
    users.filter((d) => {
      const subordinate = isSubordinate(user, d.id);
      if (type === 'workinghours') return d.id === user.id || userIsAdmin || (userCanSupervise && subordinate);
      else return d.id === user.id || userIsAdmin || userProjectManagerSupervisor(d.id);
    });
}
type PMS = Workspace['permissionSettings'] & {
  groupsCanComegoViewTimes: IdArray;
  groupsCanComegoExportTimes: IdArray;
  groupsCanComegoCreateTimes: IdArray;
  groupsCanComegoUpdateTimes: IdArray;
  groupsCanContingentViewSelf: IdArray;
  groupsCanContingentViewOther: IdArray;
};
type PMSKnown = keyof NoIndex<PMS>;
export function hasPermissionByKey(user: UserSettings, key: PMSKnown) {
  if (isAdmin(user)) return true;
  return !!user.workspace.permissionSettings[key]?.find((p: { id: string }) => hasPermission(p.id, user));
}
export function hasCreateTaskPermission(user: UserSettings, project: Project) {
  if (!project) return false;
  return userProjectTaskPerm(user, project);
}
export function hasCreateProjectPermission(user: UserSettings) {
  return hasPermissionByKey(user, 'groupsCanManageProjects');
}
export function isProjectUser(uid: string, workspace: Workspace, project: Project) {
  return hasUserInProject({ id: uid, workspace } as any, project);
}

export function useAdmin() {
  const user = inject(UserSettingsQuery);
  return fromRxValue(() => user.select().pipe(map((u) => isAdmin(u))), isAdmin(user.getValue()));
}

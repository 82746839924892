<mat-dialog-content>
  <div class="searchWrapper flex flex-col justify-stretch">
    <div class="searchInput flex flex-col items-start justify-center px-2">
      <mat-form-field appearance="fill" class="mat-form-field-compact mat-form-field-no-hint w-full">
        <input type="text" ignoreAutocomplete matInput [formControl]="search" cdkFocusInitial tabindex="0" />
        <div class="flex-center h-full" matSuffix>
          <mat-icon inline>search</mat-icon>
        </div>
      </mat-form-field>
    </div>
    <div class="searchContainer flex flex-col">
      <mat-list dense *ngIf="data$filtered | async as dataFiltered">
        <ng-container *ngFor="let item of dataFiltered; trackBy: trackId">
          <mat-list-item
            matRipple
            (click)="this.selectItem(item)"
            class="cursor-pointer"
            [class.bg-primary-2]="item.selected"
          >
            <div class="flex flex-auto items-center space-x-2.5">
              <div class="relative flex h-8 w-8 items-center justify-center">
                <app-user-avatar
                  size="38px"
                  [ngClass]="{
                    'absolute rounded-full': true,
                    'opacity-40': item.selected,
                  }"
                  [userId]="item.id"
                ></app-user-avatar>
                <div class="z-10 flex-center h-8 w-8 rounded-full text-center text-lg leading-8" *ngIf="item.selected">
                  <mat-icon inline>check</mat-icon>
                </div>
              </div>
              <div class="flex flex-auto flex-col leading-none">
                <span>{{ item.name }}</span>
                <span class="mat-hint text-sm">{{ item.email }}</span>
              </div>
              <div class="flex items-center space-x-1.5">
                <div class="text-lg leading-none">
                  <mat-icon *ngIf="item.admin" inline class="mat-text-primary cursor-default" matTooltip="Administrator"
                    >security</mat-icon
                  >
                </div>
                @if (item.subordinate) {
                  <div class="text-lg leading-none">
                    <mat-icon
                      inline
                      class="cursor-default"
                      [matTooltip]="'workspace.supervisors.subordinates' | translate"
                      >supervisor_account</mat-icon
                    >
                  </div>
                }
              </div>
            </div>
          </mat-list-item>
        </ng-container>
        <ng-container *ngTemplateOutlet="noItems; context: { data: dataFiltered }"></ng-container>
      </mat-list>
      <ng-template #noItems let-dataFiltered="data">
        <div class="flex flex-col items-center justify-center p-2" *ngIf="dataFiltered.length === 0">
          <div class="mat-hint">{{ 'utils.noitems' | translate }}</div>
        </div>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-content class="!p-1">
  <mat-calendar
    [selected]="this.selectedDate$ | async"
    [startAt]="this.selectedDate$ | async"
    [maxDate]="this.settings$allowFutureTimeTracking | async"
    (selectedChange)="this.close($event)"
    #calview
    color="primary"
    class="calendar-item"
  ></mat-calendar>
</mat-dialog-content>

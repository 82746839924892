import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

@Directive({
  selector: 'app-user-avatar[size]'
})
export class UserAvatarSizeDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  constructor(private el: ElementRef<HTMLElement>) {}
  private _size = new BehaviorSubject<string>(null);
  readonly size$ = this._size.asObservable().pipe(distinctUntilChanged());
  get size() {
    return this._size.getValue();
  }
  @Input()
  set size(val: string) {
    this._size.next(val);
  }
  ngOnInit() {
    this.size$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.el?.nativeElement.setAttribute('style', `height: ${x}; width: ${x};`);
    });
  }
}

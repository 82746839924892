import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChangedJson, fromRxValueWithState } from '@app/_helpers/utils';
import { BehaviorSubject, Subject, combineLatest, defer } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';

import { UserImagesQuery, UserService, UserSettingsQuery } from 'timeghost-api';
@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  private _defaultImageSource = new BehaviorSubject<string>('/assets/img/default.png');
  readonly defaultImageSource$ = this._defaultImageSource.asObservable().pipe(distinctUntilChanged());
  get defaultImageSource() {
    return this._defaultImageSource.getValue();
  }
  @Input()
  defaultImageTemplate: TemplateRef<any>;
  @Input()
  defaultImageTemplateData: any = {};
  @Input()
  set defaultImageSource(val: string) {
    this._defaultImageSource.next(val);
  }

  constructor(
    private ua: UserService,
    private userImages: UserImagesQuery,
    private userSettingsQuery: UserSettingsQuery,
  ) {}

  ngOnInit() {}
  private _userId = new BehaviorSubject<string>(null);
  readonly userId$ = this._userId.asObservable().pipe(distinctUntilChanged());
  get userId() {
    return this._userId.getValue();
  }
  @Input()
  set userId(val: string) {
    this._userId.next(val);
  }
  private _sourceSize = new BehaviorSubject<number>(96);
  readonly sourceSize$ = this._sourceSize.asObservable().pipe(distinctUntilChanged());
  get sourceSize() {
    return this._sourceSize.getValue();
  }
  @Input()
  set sourceSize(val: number) {
    this._sourceSize.next(val);
  }
  private _size = new BehaviorSubject<number>(32);
  readonly size$ = this._size.asObservable().pipe(distinctUntilChanged());
  get size() {
    return this._size.getValue();
  }
  set size(val: number) {
    this._size.next(val);
  }

  readonly image = fromRxValueWithState(({ setLoading }) =>
    defer(() => {
      setLoading(true);
      return combineLatest([
        this.userId$.pipe(startWith(this.userId)),
        this.sourceSize$.pipe(startWith(this.sourceSize)),
        this.userSettingsQuery
          .select()
          .pipe(
            distinctUntilChangedJson((d) => d.workspace.users?.length && d.workspace.users.map((s: any) => s.avatar)) ||
              null,
          ),
      ]).pipe(
        map(
          ([
            customUserId,
            size,
            {
              id: currentId,
              workspace: { users: wusers },
            },
          ]) => {
            const uid = customUserId ?? currentId;
            const user = wusers.find((d: any) => d.id === uid && d.avatar);
            if (uid === 'default') return null;
            // @ts-ignore
            return (user?.avatar as string) || '/assets/img/photo.jpg';
          },
        ),
        tap(() => setLoading(false)),
        takeUntilDestroyed(),
      );
    }),
  );
}

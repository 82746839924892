import colorLib from '@kurkle/color';
const isPatternOrGradient = (value: any) => value instanceof CanvasGradient || value instanceof CanvasPattern;

function fromPalette(value: any) {
  return isPatternOrGradient(value) ? value : colorLib(value);
}

function getHoverColor(value: any) {
  return isPatternOrGradient(value) ? value : colorLib(value).saturate(0.5).darken(0.1).hexString();
}
export default {
  fromPalette,
};

import { endOfDay, startOfDay } from 'date-fns/esm';

export function clampDay(currentDate: Date) {
  return (val: Date) => {
    if (val.getDate() > currentDate.getDate()) {
      return endOfDay(currentDate);
    } else if (val.getDate() < currentDate.getDate()) {
      return startOfDay(currentDate);
    }
    return val;
  };
}
export function removeOffsetFromDate(date: Date) {
  const offset = new Date().getTimezoneOffset() * -1 * 60 * 1000;
  return new Date(date.getTime() + offset);
}

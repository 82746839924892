import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'input[ignoreAutocomplete]',
})
export class IgnoreAutocompleteDirective {
  constructor(private el: ElementRef<HTMLInputElement>) {
    el.nativeElement.setAttribute('list', 'autocompleteOff'), el.nativeElement.setAttribute('autocomplete', 'off');
  }
}

import { AfterContentInit, AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FadeInOut, FadeOut } from '../../animations/fade';

const fadeInTimeMS = 1000;

class LogoSettings {
  public rounded = false;
  public circle = false;
  public url: string = null;
  // public get safeUrl() {
  //   return this.sani
  // }
  public enabled = false;
  public width: number;
  public height: number;
  constructor(data: Partial<LogoSettings>) {
    Object.assign(this, data);
  }
}

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [FadeInOut, FadeOut],
})
export class LoaderComponent implements OnInit, AfterViewInit, AfterContentInit {
  @HostBinding('class')
  @Input('class')
  classList: string = '';

  private _isActiveAsync: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isActiveAsync = this._isActiveAsync.asObservable();
  @Input()
  set isActive(val: boolean) {
    this.setIsActive(val);
  }
  getIsActive() {
    return this.isActiveAsync;
  }
  setIsActive(val: boolean) {
    this._isActiveAsync.next(val);
  }
  @Input()
  enableStatus = false;
  @Input()
  currentStatus: string;
  @Input()
  isAfterViewInit = false;
  @Input()
  width = 200;
  @Input()
  circleStrokeWidth = 2;
  @Input()
  fixed = false;
  @Input()
  logo: LogoSettings = new LogoSettings({});
  @Input()
  enableCircle = false;
  @Input()
  customBackColor: string;
  @Input()
  customLoadingColor: string;
  imageIsLoaded = false;

  constructor(private loaderel: ElementRef) {}
  ngOnInit(): void {
    if (this.enableCircle !== false && this.width === 200) {
      this.width = 20;
    }
  }
  ngAfterViewInit(): void {}
  ngAfterContentInit() {
    if (this.isAfterViewInit) {
      const app = this;
      setTimeout(function () {
        app.isActive = false;
      }, fadeInTimeMS);
    }
  }
}

import { EventEmitter } from 'events';
let sleepCheckInterval: any;
let lastSleepCheck: any = false;
const SLEEP_CHECK_INTERVAL = 10e3;
const NUM_MISSABLE_INTERVALS = 10;
const emitter = new EventEmitter();
/**
 * create event emitter on wakeup
 * @param cb listener function
 * @returns timeout stamp
 */
export default function (cb: () => void) {
  emitter.on('wakeup', cb);

  if (!sleepCheckInterval) {
    // setup interval
    sleepCheckInterval = setInterval(function () {
      const t = Date.now();
      if (lastSleepCheck && t - lastSleepCheck > SLEEP_CHECK_INTERVAL * NUM_MISSABLE_INTERVALS)
        emitter.emit('wakeup', lastSleepCheck); // missed NUM_MISSABLE_INTERVALS checks, let's run the callbacks
      lastSleepCheck = t;
    }, SLEEP_CHECK_INTERVAL);
  }

  // unreference the timer so that the program can close
  if (sleepCheckInterval?.unref) sleepCheckInterval.unref();

  return sleepCheckInterval;
}

import { Pipe, PipeTransform } from '@angular/core';
import { Project, ProjectsQuery } from 'timeghost-api';

@Pipe({
  name: 'projectProxy',
})
export class ProjectProxyPipe implements PipeTransform {
  constructor(private projectsQuery: ProjectsQuery) {}
  transform(value: { id: string }): Project {
    return value?.id ? this.projectsQuery.getEntity(value.id) ?? (value as any) : value;
  }
}

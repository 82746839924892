import { pushError } from '@app/_helpers/globalErrorHandler';
import { asyncWrapTimeout } from '@app/_helpers/utils';
import { FrillTimeoutError } from '@app/components/frill-button/frill-button.service';
import { Logger } from 'timeghost-api';
const log = new Logger('Frill JS');
export interface FrillOptions {
  theme: string;
  ssoToken: string;
  onUpdate: (ev: string, data: any) => void;
}
export const WIDGET_ID = {
  NORMAL: '28227d6b-07b2-4b72-8252-c720ade60a12',
  ADMIN: 'e88f1786-0f58-42a4-819c-d9dc35ffec32',
};
const SCRIPT_URL = `https://widget.frill.co/v2/widget.js`;
export const isFrillInitialized = () => window.Frill && 'Frill' in window;
export const initFrillJs = (() => {
  let frillWidgetInstance: any;
  let config: any;
  return (widgetId: string, options?: Partial<FrillOptions>) => {
    const isLoaded = isFrillInitialized();
    if (isLoaded) {
      if (config?.key !== widgetId) {
        frillWidgetInstance?.destroy?.();
        frillWidgetInstance = null;
      } else return Promise.resolve(frillWidgetInstance);
    }
    window.Frill_Config = [
      {
        key: widgetId,
        ssoToken: options?.ssoToken,
      },
    ];
    return new Promise((resolve, reject) => {
      if (isLoaded) resolve(null);
      const script = document.createElement('script');
      script.src = SCRIPT_URL;
      script.onload = resolve;
      script.dataset.js = 'frill';
      script.async = true;
      script.onerror = reject;
      document.head.appendChild(script);
    })
      .then(() => window.Frill)
      .then((Frill: any) => {
        if (!Frill) throw new Error('Frill widget instance not loaded or not found');
        return asyncWrapTimeout(() => {
          return new Promise((finishLoad, reject) => {
            config = {
              ...(window.Frill_Config?.[0] ?? {}),
              callbacks: {
                onReady: (w: any) => {
                  log.debug('frill widget ready');
                  finishLoad(w);
                },
                onBadgeCount: ({ count }: { count: number }) => options?.onUpdate?.('badgeCount', count),
              },
              settings: {
                advanced: {
                  hidePoweredByFrill: true,
                },
                appearance: {
                  theme: {
                    idx: options?.theme ?? 'light',
                  },
                },
                launcher: {
                  type: 'null',
                },
                view: {
                  type: 'sidebar',
                },
              },
            };
            window.Frill_Config = [config];
            log.debug('init', widgetId, config);
            if (!('Frill' in window)) return reject(new Error('failed to find frill instance'));
            Frill.widget(config);
            return Frill;
          });
        }, 10000).catch((err) => {
          if (err instanceof Error && err.name === 'timeoutErr')
            pushError((err = new FrillTimeoutError('Frill widget failed to respond in time.')));
          else pushError(err);
          return Promise.reject(err);
        });
      })
      .then((Frill: any) => {
        return (frillWidgetInstance = Frill);
      })
      .catch((err) => {
        log.error(err);
        return Promise.reject(err);
      });
  };
})();

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'tg-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { text: string; strings?: { [key: string]: string } },
    private ref: MatDialogRef<ConfirmDialogComponent>,
  ) {}

  ngOnInit(): void {}
  @HostListener('document:keydown.escape')
  onEscape() {
    this.ref.close(false);
  }
  get message() {
    return this.data.text;
  }
  get strings() {
    return this.data.strings || {};
  }
}

<form [formGroup]="group" (submit)="$event.preventDefault()" cdkTrapFocus class="flex flex-col">
  <mat-toolbar class="bg-transparent" id="record_toolbar_wrapper">
    <div class="mr-2 flex w-full min-w-0">
      <mat-form-field
        appearance="fill"
        class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain min-w-0 flex-auto items-stretch !text-base"
        matAutocompleteOrigin
        #descWrapper="matAutocompleteOrigin"
        role="listbox"
      >
        <input
          type="text"
          matInput
          id="record_toolbar_name"
          #nameFocus
          [placeholder]="
            (SelectedRecordMode !== modes.Timer ? 'RECORD_TOOLBAR_DESCRIPTION_PAST' : 'RECORD_TOOLBAR_DESCRIPTION')
              | translate
          "
          formControlName="name"
          [matAutocomplete]="descriptionList"
          [matAutocompleteConnectedTo]="descWrapper"
          (keydown.enter)="$event.preventDefault(); $event.stopPropagation()"
          (keyup.arrowdown)="focusAutocomplete()"
        />
        <div
          matSuffix
          class="flex h-full flex-row items-stretch justify-start gap-2"
          *ngIf="{
            name: (value$project.value | defaultEntityTranslate: 'project' : 'project.none'),
            task: value$task | async,
            tags: value$project.value?.tags,
          } as projectDetails"
        >
          <mat-divider class="mat-divider-vertical ml-2 self-center" vertical></mat-divider>
          <button
            class="flex cursor-pointer flex-row items-center justify-start gap-1.5 border-0 shadow-none"
            id="record_toolbar_project"
            (click)="$event.preventDefault(); $event.stopPropagation(); this.openClientProjectPickerDialog($event)"
            (keyup.enter)="
              $event.preventDefault(); $event.stopPropagation(); this.openClientProjectPickerDialog($event)
            "
            matTooltip
            [matTooltipTemplate]="projectPickerTooltip"
            [matTooltipTemplateContext]="projectTooltipData$ | async"
            [matTooltipOptions]="{ delay: [500, 0] }"
            tabindex="0"
          >
            <span> {{ projectDetails.name | truncate: 20 }} </span>
            <div class="mat-bg-30 h-1 w-1 rounded-full"></div>
            <span>{{ projectDetails.task?.name || ('task.none' | translate) | truncate: 25 }}</span>
          </button>
        </div>
      </mat-form-field>
      <mat-autocomplete
        #searchACRef
        #descriptionList="matAutocomplete"
        [displayWith]="this.autoDisplayWith"
        class="!max-h-full overflow-hidden !rounded-lg"
      >
        <div class="scroll-y my-2 max-h-128 min-h-0 flex-1">
          <ng-container
            *ngIf="{
              suggestions: (entries$suggestions | async),
              times: (entries$latestTimes | async),
            } as data"
          >
            <ng-container
              *ngTemplateOutlet="
                data.suggestions?.length > 0 ? suggestionsStepperTemplate : latestTimesTemplate;
                context: data
              "
            ></ng-container>
          </ng-container>
        </div>
        <mat-option style="height: 0; display: none" tabindex="-1" [value]="null"></mat-option>
      </mat-autocomplete>
    </div>
    <div class="flex-auto"></div>
    <div class="record-toolbar-icon-button-group flex flex-row items-center justify-center" #containerAccessor>
      <button
        mat-icon-button
        [class.mat-accent]="value$tags | async"
        (click)="this.openTagPickerDialog($event)"
        [disabled]="group.disabled"
      >
        <mat-icon inline>label</mat-icon>
      </button>
      <button
        mat-icon-button
        [class.mat-accent]="value$billable | async"
        mat-button-toggle
        (click)="this.togglePayment()"
        [disabled]="group.disabled"
      >
        <mat-icon inline>attach_money</mat-icon>
      </button>
    </div>
    <div class="vertical-center ml-2" *ngIf="this.SelectedRecordMode === this.modes.Timer">
      <tg-record-toolbar-counter [start]="this.runningTime$counter | async"></tg-record-toolbar-counter>
    </div>
    <div class="vertical-separator"></div>
    <div class="vertical-center ml-2 flex flex-row items-center justify-start gap-[10px]">
      <ng-container *ngIf="this.SelectedRecordMode === this.modes.Timer; else manualMode">
        <div
          *ngIf="{ errors: errors$timer | async } as toolbar"
          [matTooltip]="
            toolbar.errors?.translate
              ? (toolbar.errors.translate | translate: toolbar.errors.args)
              : (toolbar.errors | translate)
          "
          matTooltipPosition="bottom"
        >
          <button
            mat-fab
            color="accent"
            [disabled]="
              (group$disabled | async) ||
              (!!(runningTime.value$ | async) && ((hasError.value$ | async) || !this.initialEnabled)) ||
              !!(this.isLoading$ | async)
            "
            (click)="this.togglePlaying()"
            class="submit-time-button"
            id="record_toolbar_timer_submit"
          >
            <ng-container *ngIf="!(this.isLoading$ | async); else autoLoading">
              <mat-icon inline *ngIf="!(this.runningTime.value$ | async); else pauseRecordButton">play_arrow</mat-icon>
              <ng-template #pauseRecordButton>
                <mat-icon inline>stop</mat-icon>
              </ng-template>
            </ng-container>
            <ng-template #autoLoading>
              <mat-progress-spinner [strokeWidth]="3" [diameter]="20" color="accent" [mode]="'indeterminate'">
              </mat-progress-spinner>
            </ng-template>
          </button>
        </div>
        <div></div>
      </ng-container>
      <ng-template #manualMode>
        <div class="text-small flex flex-row items-center justify-start gap-[10px]">
          <div class="flex flex-row items-center justify-end">
            <tg-time-range-control
              [(stateVisibleMode)]="stateVisibleMode"
              [control]="group.controls.time"
              class="flex items-center justify-end"
              [ngClass]="{
                'min-w-60': mode === 'range',
                'min-w-48': mode === 'duration',
              }"
              [mode]="mode"
            ></tg-time-range-control>
          </div>
          <button
            class="submit-time-button"
            mat-fab
            color="accent"
            [disabled]="
              (group$disabled | async) ||
              (hasError.value$ | async) ||
              !this.initialEnabled ||
              !!(this.isLoading$ | async)
            "
            (click)="this.saveManualData()"
            id="record_toolbar_submit"
          >
            <mat-icon inline *ngIf="!(this.isLoading$ | async); else manualLoading">check</mat-icon>
            <ng-template #manualLoading>
              <mat-progress-spinner [strokeWidth]="3" [diameter]="20" color="accent" [mode]="'indeterminate'">
              </mat-progress-spinner>
            </ng-template>
          </button>
        </div>
      </ng-template>
    </div>
    <div class="record-toolbar-side-menu ml-2">
      <button
        mat-icon-button
        [ngClass]="{
          'mat-accent': this.SelectedRecordMode == this.modes.Timer,
        }"
        #SelectionModeTimer
        (click)="this.changeMode(this.modes.Timer)"
        [disabled]="isLoading"
      >
        <mat-icon inline class>timer</mat-icon>
      </button>
      <button
        mat-icon-button
        [ngClass]="{
          'mat-accent': this.SelectedRecordMode == this.modes.Manual,
        }"
        #SelectionModeManual
        (click)="this.changeMode(this.modes.Manual)"
        [disabled]="isLoading"
      >
        <mat-icon inline class>edit</mat-icon>
      </button>
    </div>
  </mat-toolbar>
</form>
<mat-toolbar class="record-err-toolbar bg-transparent" *ngIf="errors$ | async as err" @slide>
  <div class="mat-text-primary">{{ err?.translate ? (err.translate | translate: err.args) : (err | translate) }}</div>
</mat-toolbar>
<mat-menu #timeContextMenu="matMenu">
  <ng-template matMenuContent let-data>
    <button mat-menu-item (click)="setToNow(data.prop)">{{ 'timeContextMenu.setTo.now' | translate }}</button>
    <button mat-menu-item [matMenuTriggerFor]="timeContextMenuTimeTemplates" [matMenuTriggerData]="{ $implicit: data }">
      {{ 'timeContextMenu.setToMore' | translate }}
    </button>
  </ng-template>
</mat-menu>
<ng-template #showCounter let-time>
  {{ time.start | dfnsParseIso | dfnsDifferenceInSeconds: now() | parseMsAsDuration: true : false }}
</ng-template>
<ng-template #taskSearchTemplate let-searchResult="searchResult">
  <mat-list dense>
    <ng-container
      *ngFor="let entry of searchResult?.clients || []; let last = last; let first = first; trackBy: trackId"
    >
      <h3 matSubheader class="f-bold">
        <div class="text-ellipsis text-nowrap">{{ entry | defaultEntityTranslate: 'client' : 'client.none' }}</div>
      </h3>
      <div *ngFor="let project of entry.projects || []">
        <h3
          matSubheader
          [matRipple]="!project.useAsDefault"
          (click)="
            $event.stopPropagation();
            project.selected || project.useAsDefault
              ? this.removeProject()
              : this.changeProject({
                  id: project.id,
                  name: project.name,
                  billable: project.billable,
                  client: {
                    id: entry.id,
                    name: entry.name,
                  },
                })
          "
        >
          <mat-checkbox
            [labelPosition]="'before'"
            color="primary"
            (click)="$event.preventDefault()"
            class="mat-checkbox-flex w-full"
            [checked]="project.selected"
            [disabled]="project.useAsDefault"
            >{{ project | defaultEntityTranslate: 'project' : 'project.none' }}</mat-checkbox
          >
        </h3>
        <ng-container
          *ngTemplateOutlet="
            project?.tasks.length > 0 ? autocompleteTaskItem : placeholderTask;
            context: { project: project, entry: entry, value: group.value.name }
          "
        >
        </ng-container>
      </div>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
  </mat-list>
</ng-template>
<ng-template #autocompleteTaskItem let-project="project" let-entry="entry">
  <mat-option
    class="text-wrap-line text-ellipsis pl-6"
    *ngFor="let task of project.tasks; trackBy: trackId"
    (click)="
      taskSelected($event, {
        client: { id: entry.id, name: entry.name },
        task: task,
        project: project,
      })
    "
    [value]="task.name"
    >{{ task?.name }}</mat-option
  >
</ng-template>
<ng-template #placeholderTask let-value="value">
  <mat-option style="height: 0; display: none" value="value" tabindex="-1"></mat-option>
</ng-template>
<ng-template #taskSearchLoadTemplate>
  <mat-list dense>
    <div class="m-2 flex flex-col gap-1.5">
      <div class="h-6 w-64">
        <div class="mat-skeleton"></div>
      </div>
      <div class="h-6 w-80">
        <div class="mat-skeleton"></div>
      </div>
    </div>
  </mat-list>
</ng-template>
<ng-template #projectPickerTooltip let-project="project" let-task="task" let-tags="tags">
  <div class="my-2 flex flex-col space-y-2 px-2.5 wflex-[1_0_220px]">
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>client.single</h3>
      <div class="font-semibold">{{ project?.client | defaultEntityTranslate: 'client' : 'client.none' }}</div>
    </div>
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>project.single</h3>
      <div class="font-semibold">{{ project | defaultEntityTranslate: 'project' : 'project.none' }}</div>
    </div>
    <div class="space-y-1" *ngIf="(project | projectProxy)?.description as desc">
      <h3 class="mat-hint text-sm italic" translate>Description</h3>
      <div>{{ desc | truncate: 120 }}</div>
    </div>
    <ng-container *ngIf="tags && tags.length > 0">
      <div class="space-y-1">
        <h3 class="mat-hint text-sm italic" translate>tag.plural</h3>
        <div class="flex flex-wrap gap-2">
          <div
            class="mbg-card pointer-events-none h-6 cursor-default select-none truncate rounded-full px-2.5 text-base leading-6"
            *ngFor="let item of tags; trackBy: trackId"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #timeInfoTooltip let-client="client" let-project="project" let-task="task" let-color="color">
  <div class="my-2 flex flex-col space-y-2 px-2.5 wflex-[1_0_220px]">
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>client.single</h3>
      <div class="font-semibold">
        {{ client | defaultEntityTranslate: 'client' : 'client.none' : ('client.none' | translate) }}
      </div>
    </div>
    <div class="space-y-1" *ngIf="(project | projectProxy)?.description as desc">
      <h3 class="mat-hint text-sm italic" translate>Description</h3>
      <div>{{ desc | truncate: 120 }}</div>
    </div>
  </div>
</ng-template>
<ng-template
  #feedInfoTooltip
  let-name="name"
  let-description="description"
  let-icon="icon"
  let-iconUrl="iconUrl"
  let-entry="entry"
>
  <div class="flex min-w-32 flex-row items-start space-x-2 wflex-[1_0_220px]">
    <div class="flex flex-shrink-0 items-center">
      <img *ngIf="iconUrl != null; else msIcon" [src]="iconUrl" style="width: 32px" />
      <ng-template #msIcon>
        <ms-icon style="font-size: 32px; height: 32px; width: 32px" [icon]="icon"> </ms-icon>
      </ng-template>
    </div>
    <div class="flex flex-col">
      <div class="font-bold">{{ name | truncate: 100 }}</div>
      <div *ngIf="description as desc" class="mt-1 text-sm">{{ desc | parseFeedDescription | truncate: 160 }}</div>
      <ng-container *ngIf="!entry.isAllDay">
        <div class="mt-1 flex flex-col">
          <div class="flex items-center space-x-1">
            <div class="flex flex-row items-center justify-start gap-1.5">
              <span class="mat-hint" *ngIf="entry.start as startDate">{{
                startDate | dfnsParseIso | formatDate: 'time'
              }}</span>
              <span class="mat-hint">-</span>
              <span class="mat-hint" *ngIf="entry.end as endDate">{{
                endDate | dfnsParseIso | formatDate: 'time'
              }}</span>
            </div>
          </div>
          <span class="mat-hint">{{ entry.start | dfnsParseIso | dfnsFormat: 'dd. MMMM - EEEE' }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #noSearchEntries>
  <div class="mx-16px h-10">
    <p class="mat-hint align-middle leading-10">{{ 'utils.noitems-search' | translate }}</p>
  </div>
</ng-template>
<ng-template #recentTimeTemplate let-meta let-last="last" let-entry="data">
  <button
    class="mbg-card mb-1.5 flex h-8 cursor-pointer items-center space-x-1 rounded-full px-2.5 text-sm opacity-80 hover:opacity-100"
    matTooltip
    [matTooltipTemplate]="timeInfoTooltip"
    [matTooltipTemplateContext]="{
      project: entry.project,
      client: entry.client,
      task: entry.task,
      color: entry.project.color ?? getProjectColor(entry.project.id),
    }"
    [class.mr-2]="!last"
    (click)="$event.stopPropagation(); timeSelected(entry)"
  >
    <div
      *ngIf="entry.project.color ?? getProjectColor(entry.project.id) as color"
      class="project-color-dot -mt-px"
      [style.backgroundColor]="color"
    ></div>
    <span class="truncate font-bold uppercase">{{
      (entry.project | defaultEntityTranslate: 'project' : 'project.none' | translate) +
        ' - ' +
        (entry.task?.name || ('task.none' | translate)) | truncate: 80
    }}</span>
  </button>
</ng-template>
<ng-template #recentFeedTemplate let-meta let-last="last" let-entry="data">
  <button
    class="mbg-card mb-1.5 flex h-8 cursor-pointer items-center space-x-2 rounded-full px-2.5 text-sm opacity-80 hover:opacity-100"
    matTooltip
    [matTooltipTemplate]="feedInfoTooltip"
    [matTooltipTemplateContext]="{
      name: entry.name,
      description: entry.description,
      icon: entry.icon,
      iconUrl: entry.iconUrl,
      entry: entry,
    }"
    [class.mr-2]="!last"
    (click)="$event.stopPropagation(); feedSelected(entry)"
  >
    <div class="flex flex-shrink-0 items-center">
      <img *ngIf="entry.iconUrl != null; else msIcon" [src]="entry.iconUrl" class="w-5" />
      <ng-template #msIcon>
        <ms-icon class="h-5 w-5 text-sm" [icon]="entry.icon"> </ms-icon>
      </ng-template>
    </div>
    <div class="flex items-center space-x-1">
      <span class="truncate font-bold uppercase">{{ entry.name | truncate: 40 }}</span>
    </div>
  </button>
</ng-template>
<ng-template #latestTimesTemplate let-entries="times">
  <ng-container *ngIf="entries">
    <div class="my-2 overflow-hidden pb-2">
      <ng-container *ngIf="!group.value.name || group.value.name.length <= 0">
        <ng-container *ngTemplateOutlet="autocompleteHeader"></ng-container>
      </ng-container>
      <div class="flex flex-col space-y-6">
        <div class="flex flex-col">
          <h1 class="mat-hint mx-16px mb-2 text-base font-semibold" translate>task.plural</h1>
          <div class="-mb-16px flex flex-grow flex-wrap px-16px pb-16px">
            <ng-container *ngFor="let entry of entries.times; let last = last; trackBy: trackId">
              <ng-container
                *ngTemplateOutlet="
                  recentTimeTemplate;
                  context: {
                    $implicit: entry,
                    data: entry.data,
                    type: entry.type,
                    last: last,
                  }
                "
              ></ng-container>
            </ng-container>
          </div>
          <div class="mx-16px flex items-center justify-end">
            <button
              mat-button
              color="primary"
              class="link-button"
              *ngIf="entries.showMoreTasks !== undefined"
              (click)="showMoreTasks.value = !entries.showMoreTasks"
            >
              {{ (showMoreTasks.value ? 'utils.show_less' : 'utils.show_more') | translate }}
            </button>
          </div>
          <div class="mx-16px -mb-16px flex flex-grow flex-wrap pb-16px" *ngIf="!entries.times?.length">
            <p class="mat-hint mx-16px text-base italic opacity-60" translate>utils.noitems</p>
          </div>
        </div>
        <div class="flex flex-col" *ngIf="!group.value.name || group.value.name.length <= 0">
          <h1 class="mat-hint mx-16px mb-2 text-base font-semibold" translate>feed.name</h1>
          <div class="-mb-16px flex flex-grow flex-wrap px-16px pb-16px">
            <ng-container *ngFor="let entry of entries.feed; let last = last; trackBy: trackId">
              <ng-container
                *ngTemplateOutlet="
                  recentFeedTemplate;
                  context: {
                    $implicit: entry,
                    data: entry.data,
                    type: entry.type,
                    last: last,
                  }
                "
              ></ng-container>
            </ng-container>
          </div>
          <div class="mx-16px flex items-center justify-end">
            <button
              mat-button
              color="primary"
              class="link-button"
              *ngIf="entries.showMoreFeed !== undefined"
              (click)="showMoreFeed.value = !entries.showMoreFeed"
            >
              {{ (showMoreFeed.value ? 'utils.show_less' : 'utils.show_more') | translate }}
            </button>
          </div>
          <div class="mx-16px -mb-16px flex flex-grow flex-wrap pb-16px" *ngIf="!entries.feed?.length">
            <p class="mat-hint mx-16px text-base italic opacity-60" translate>utils.noitems</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #suggestionsStepperTemplate let-entries="suggestions">
  <div class="my-2 overflow-hidden pb-2">
    <ng-container *ngTemplateOutlet="autocompleteHeader"></ng-container>
    <div class="-mb-16px flex flex-grow flex-wrap gap-4 px-16px pb-16px">
      <ng-container *ngFor="let entry of entries; let last = last; trackBy: trackId">
        <mat-card
          class="mat-card-button mat-bg mb-2 cursor-pointer !rounded !px-3 !py-2"
          (click)="$event.stopPropagation(); submitSuggestion({ project: entry, task: entry.task })"
          [class.border-active]="entry.selected"
        >
          <mat-card-content>
            <div class="flex flex-auto flex-col">
              <div class="flex flex-row items-center justify-start gap-2">
                <div
                  class="project-color-dot self-center"
                  *ngIf="entry.color as color"
                  [style.backgroundColor]="color"
                  [matTooltip]="'project.color' | translate"
                ></div>
                <h3 class="mb-0 text-base font-bold">
                  {{ entry | defaultEntityTranslate: 'project' : 'project.none' | truncate: 100 }}
                </h3>
                <div class="mat-bg-30 h-1 w-1 rounded-full"></div>
                <span>{{ entry.task?.name || ('task.none' | translate) | truncate: 40 }}</span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #autocompleteHeader>
  <div class="mb-4 flex flex-row items-center justify-start gap-2 px-16px">
    <mat-icon svgIcon="logoIcon" class="logo-icon"></mat-icon>
    <div class="flex flex-col">
      <h4 class="my-0" translate>utils.suggestions</h4>
      <span class="mat-hint" translate>buzz.ki-suggestions</span>
    </div>
  </div>
</ng-template>

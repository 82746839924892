import { Injectable } from '@angular/core';
import { Project, Client, ClientsQuery, ProjectsQuery } from 'timeghost-api';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { map, startWith, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ClientProjectModel } from '@app/classes/client-project-model';
import { Logger } from 'timeghost-api';
import { addMilliseconds, differenceInHours, intervalToDuration } from 'date-fns/esm';
import { NaNZeroify } from '@app/_helpers/utils';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private _clientprojectMerge: BehaviorSubject<ClientProjectModel[]> = new BehaviorSubject([]);
  readonly clientProjects$ = this._clientprojectMerge.asObservable().pipe(startWith([]), distinctUntilChanged());
  get clientProjects() {
    return this._clientprojectMerge.getValue();
  }

  // private _tags: BehaviorSubject<Tag[]> = new BehaviorSubject([]);
  // tags = this._tags.asObservable();
  constructor(private clientsQuery: ClientsQuery, private projectsQuery: ProjectsQuery) {
    this.init();
  }
  init() {
    this.getClientProjectMerge().subscribe((x) => {
      this._clientprojectMerge.next(x);
    });
    // this.getTags().subscribe(x => {
    //   this._tags.next(x);
    // })
  }
  // private getTags() {
  //   return this.tagService.getTagsWithChanges();
  // }
  // getTagById(id: string) {
  //   return this.tags.pipe(
  //     startWith(null),
  //     debounceTime(500),
  //     filter(x => x !== null),
  //     map(x => x.filter(c => c.id === id)),
  //     first()
  //   );
  // }
  // getTagsByTask(id: string) {
  //   return this.tags.pipe(
  //     startWith(null),
  //     debounceTime(500),
  //     filter(x => x !== null),
  //     map(x => x.filter(c => c.id === id)),
  //     first()
  //   );
  // }
  private getClientProjectMerge() {
    let rx = combineLatest([this.clientsQuery.selectAll(), this.projectsQuery.selectAll()]).pipe(
      debounceTime(50),
      map(([clients, projects]: [Client[], Project[]]) => {
        return (
          clients?.map(
            (x) =>
              new ClientProjectModel({
                client: x,
                projects: projects?.filter((_y) => !_y.completed && _y.client && _y.client.id === x.id) || [],
              })
          ) || []
        );
      })
    );
    return rx;
  }
  static parseMS(_msValue: number, options?: Partial<ParseMSOptions>) {
    const x = NaNZeroify(_msValue);
    const startDate = new Date();
    const endDate = addMilliseconds(startDate.getTime(), x);
    let _interv = intervalToDuration({
      start: startDate,
      end: endDate,
    });
    const isNegative = endDate < startDate;
    let m = {
      ..._interv,
      hours: differenceInHours(startDate, endDate),
    };
    let _options: ParseMSOptions = {
      showHours: true,
      showMinutes: true,
      showSeconds: true,
      showNegative: true,
    };
    if (m.hours < 0) m.hours = (m.hours * -1).clampThis(0);
    options = Object.assign(_options, options);
    let hours = (~~m.hours).toFixed(0);
    let minutes = (~~m.minutes).toFixed(0);
    let seconds = (~~m.seconds).toFixed(0);
    if (m.hours < 10) {
      hours = `0${hours}`;
    }
    if (m.minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (m.seconds < 10) {
      seconds = `0${seconds}`;
    }
    let ret: string = '';
    if (options.showHours) {
      ret += `${hours}:`;
    }
    if (options.showMinutes) {
      ret += `${minutes}:`;
    }
    if (options.showSeconds) {
      ret += `${seconds}:`;
    }
    if (ret.endsWith(':')) {
      ret = ret.slice(0, ret.length - 1);
    }
    if (options.showNegative) return `${isNegative ? '-' : ''}${ret}`;
    return ret;
  }
}
const log = new Logger(UtilService.name);

export interface ParseMSOptions {
  showHours: boolean;
  showMinutes: boolean;
  showSeconds: boolean;
  showNegative: boolean;
}

<app-loader [isActive]="this.isLoading" [isAfterViewInit]="false" [enableCircle]="true"></app-loader>
<h1 mat-dialog-title>
  <div class="flex flex-row items-center justify-start">
    <div>{{ 'tag.create' | translate }}</div>
    <div class="flex-auto"></div>
    <button mat-icon-button matDialogClose tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>
<mat-dialog-content cdkTrapFocus>
  <form *ngIf="this.group as g" [formGroup]="g" class="mb-4">
    <div class="flex flex-col items-stretch justify-start gap-[25px]">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required cdkInitialFocus />
        <mat-error *ngIf="this.formNameErrors as error">{{ error.content | translate: error.args || {} }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'tag.type' | translate }}</mat-label>
        <mat-select formControlName="tagType">
          <mat-option [value]="0"> {{ 'tag.project' | translate }} </mat-option>
          <mat-option [value]="1"> {{ 'tag.time' | translate }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled" *ngIf="workspace$createPermission | async; else createBlocked">
  <button
    mat-flat-button
    class="mat-action-filled"
    color="primary"
    (click)="this.createNewTag()"
    [disabled]="this.group.invalid"
  >
    {{ 'tag.create' | translate }}
  </button>
</mat-dialog-actions>
<ng-template #createBlocked>
  <mat-dialog-actions class="m-0" align="center">
    <p class="text-warn m-0 text-center" translate>workspace.errors.admin-create-tag</p>
  </mat-dialog-actions>
</ng-template>

export function ensureClassIsDecorated(instance: InstanceType<any>, property: string): never | void {
  const prototype = Object.getPrototypeOf(instance);
  const missingDecorator = !(property in prototype);

  if (missingDecorator) {
    throw new Error(
      'this operator cannot be used inside directives or ' +
        'components or providers that are not decorated with ' +
        property +
        ' decorator'
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, maxLength: number, maxRows?: number): string {
    if (!value) return value;
    let requireTruncDots = false;
    let newLineDot = false;
    if (value.length >= maxLength) {
      value = value.substring(0, value.length.clamp(0, maxLength) - 3).replace(/(\.+)$/, '');
      requireTruncDots = true;
    }
    if (maxRows !== undefined) {
      const nval = value.split('\n', maxRows + 1);
      if (nval.length > maxRows) {
        value = nval.slice(0, maxRows).join('\n');

        requireTruncDots = true;
        newLineDot = true;
      }
    }
    if (requireTruncDots) value = value.replace(/(\.+)$/, '') + (newLineDot ? '\n...' : '...');
    return value;
  }
}

import { trigger, transition, style, animate, query, group, state } from '@angular/animations';

export const SlideUpDownSync = trigger('slideQuery', [
  transition(':enter, :leave', [
    query(':enter', [
      style({
        transform: 'translateY(50%)',
        opacity: 0,
      }),
      animate(
        '.35s cubic-bezier(1,.13,.44,1.05)',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
    ]),
    query(':leave', [
      animate(
        '.35s cubic-bezier(1,.13,.44,1.05)',
        style({
          transform: 'translateY(50%)',
          opacity: 0,
        })
      ),
    ]),
  ]),
]);

export const ObjectSlideAnimation = trigger('ObjectSlideAnimation', [
  transition(':enter', [
    style({ transform: 'translateY(100px)', opacity: 0 }),
    animate('0.25s 0.1s cubic-bezier(.63,.61,.29,.96)', style({ transform: 'translateY(0)', opacity: 1 })),
    /* order */
    /* 1 */
    // query(':enter, :leave', style({ opacity: 0 }), { optional: true }),
    // query(
    //   ':leave',
    //   [
    //     style({ transform: 'translateY(0%)', opacity: 1 }),
    //     animate('0.175s cubic-bezier(.63,.61,.29,.96)', style({ transform: 'translateY(-25%)', opacity: 0 }))
    //   ],
    //   { optional: true }
    // ),
    // query(
    //   ':enter',
    //   [
    //     style({ transform: 'translateY(-25%)', opacity: 0 }),
    //     animate('0.125s cubic-bezier(.63,.61,.29,.96)', style({ transform: 'translateY(0%)', opacity: 1 }))
    //   ],
    //   { optional: true }
    // )
  ]),
]);
export const SlideUpDown = trigger('slide', [
  transition(':enter', [
    style({
      transform: 'translateY(50%)',
      opacity: 0,
      height: 0,
    }),
    animate(
      '.35s cubic-bezier(1,.13,.44,1.05)',
      style({
        transform: 'translateY(0)',
        opacity: 1,
        height: '*',
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '.35s cubic-bezier(1,.13,.44,1.05)',
      style({
        transform: 'translateY(50%)',
        opacity: 0,
        height: 0,
      })
    ),
  ]),
  transition('hide => show', [
    style({
      transform: 'translateY(50%)',
      opacity: 0,
      height: 0,
    }),
    animate(
      '.35s cubic-bezier(1,.13,.44,1.05)',
      style({
        transform: 'translateY(0)',
        opacity: 1,
        height: '*',
      })
    ),
  ]),
  transition('show => hide', [
    animate(
      '.35s cubic-bezier(1,.13,.44,1.05)',
      style({
        transform: 'translateY(50%)',
        opacity: 0,
        height: 0,
      })
    ),
  ]),
  transition('init <=> *', [
    animate(
      '0s linear',
      style({
        transform: 'translateY(50%)',
        opacity: 0,
        height: 0,
      })
    ),
  ]),
  state('show', style({ height: '*', opacity: '1', transform: 'translateY(0)' })),
  state('hide', style({ height: '0px', opacity: '0', transform: 'translateY(50%)' })),
  state('init', style({ height: '0px', opacity: '0', transform: 'translateY(50%)' })),
]);
export const FadeInOut = trigger('fade', [
  transition(':enter', [
    // :enter is alias to 'void => *'
    style({ opacity: 0 }),
    animate(300, style({ opacity: 1 })),
  ]),
  transition(':leave', [
    // :leave is alias to '* => void'
    animate(300, style({ opacity: 0 })),
  ]),
]);
export const FadeOut = trigger('fadeOut', [
  transition(':leave', [
    // :leave is alias to '* => void'
    animate(300, style({ opacity: 0 })),
  ]),
]);
export const RouteSlide = trigger('routerAnimation', [
  transition('* <=> *', [
    // Initial state of new route
    query(
      ':enter',
      style({
        position: 'fixed',
        width: '100%',
        transform: 'translateX(-100%)',
      }),
      { optional: true }
    ),

    // move page off screen right on leave
    query(
      ':leave',
      animate(
        '500ms ease',
        style({
          position: 'fixed',
          width: '100%',
          transform: 'translateX(100%)',
        })
      ),
      { optional: true }
    ),

    // move page in screen from left to right
    query(
      ':enter',
      animate(
        '500ms ease',
        style({
          opacity: 1,
          transform: 'translateX(0%)',
        })
      ),
      { optional: true }
    ),
  ]),
]);
export const RouteSlideUpDown = trigger('routerslide', [
  transition('* <=> *', [
    query(':enter, :leave', style({ display: 'block' }), { optional: true }),
    group([
      // block executes in parallel
      query(
        ':enter',
        [
          style({ transform: 'translateY(50%)' }),
          animate(
            '.35s cubic-bezier(1,.13,.44,1.05)',
            style({
              transform: 'translateY(0%)',
              opacity: 1,
            })
          ),
        ],
        { optional: true }
      ),
      query(
        ':leave',
        [
          style({ transform: 'translateY(0%)' }),
          animate(
            '.35s cubic-bezier(1,.13,.44,1.05)',
            style({
              transform: 'translateY(50%)',
              opacity: 0,
            })
          ),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);
export const ObjectScale = trigger('objectScale', [
  transition(':enter', [
    style({ transform: 'scale(1.25)', opacity: 0 }),
    animate('0.25s 0.1s cubic-bezier(.63,.61,.29,.96)', style({ transform: 'scale(1)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1 }),
    animate('0.25s 0.1s cubic-bezier(.63,.61,.29,.96)', style({ transform: 'scale(1.25)', opacity: 1 })),
  ]),
  transition('* <=> *', [
    query(':enter, :leave', style({}), { optional: true }),
    group([
      // block executes in parallel
      query(
        ':enter',
        [
          style({ transform: 'scale(1.25)', opacity: 0 }),
          animate(
            '.35s cubic-bezier(1,.13,.44,1.05)',
            style({
              transform: 'scale(1)',
              opacity: 1,
            })
          ),
        ],
        { optional: true }
      ),
      query(
        ':leave',
        [
          style({ transform: 'scale(1)' }),
          animate(
            '.35s cubic-bezier(1,.13,.44,1.05)',
            style({
              transform: 'scale(1.25)',
              opacity: 0,
            })
          ),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);
export const SlideUpDownBump = trigger('slidebump', [
  transition(':enter', [
    style({
      transform: 'translateY(50%)',
      opacity: 0,
    }),
    animate(
      '.6s cubic-bezier(.78,.03,.16,1.33)',
      style({
        transform: 'translateY(0)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '.6s cubic-bezier(.78,.03,.16,1.33)',
      style({
        transform: 'translateY(50%)',
        opacity: 0,
      })
    ),
  ]),
]);

export const MaterialScale = trigger('matScale', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(0.965)' }),
    animate('200ms cubic-bezier(0.25, 0.6, 0.25, 1)', style({ opacity: 1, transform: 'scale(1)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'scale(1)' }),
    animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ opacity: 0, transform: 'scale(0.9)' })),
  ]),
]);
export const MaterialScaleVariable = (scaleVal: number, name: string = 'matScale') => {
  return trigger(name, [
    transition(':enter', [
      style({ opacity: 0, transform: `scale(${scaleVal.toFixed(2)})` }),
      animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ opacity: 1, transform: 'scale(1)' })),
    ]),
    transition(':leave', [
      style({ opacity: 1, transform: 'scale(1)' }),
      animate(
        '200ms cubic-bezier(0.25, 0.8, 0.25, 1)',
        style({ opacity: 0, transform: `scale(${scaleVal.toFixed(2)})` })
      ),
    ]),
  ]);
};

<div [class.bg-primary-2]="entry.selected" class="flex flex-row items-center justify-start">
  <mat-list-item matRippleDisabled class="cursor-pointer truncate" (click)="select.emit(entry)">
    <mat-radio-button
      [labelPosition]="'after'"
      class="mat-checkbox-flex mat-checkbox-radio w-full"
      (click)="$event.preventDefault()"
      color="primary"
      [checked]="entry.selected"
      [name]="entry.id"
      tabindex="0"
    >
      <span class="truncate"> {{ entry.name }} </span>
    </mat-radio-button>
  </mat-list-item>
  <ng-container *ngTemplateOutlet="customMenu ? customMenu : defaultMenu; context: { $implicit: entry }"></ng-container>
</div>
<ng-template #defaultMenu></ng-template>

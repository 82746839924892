export const translateArgs = (
  args: { [key: string]: any },
  mutatorFn: (key: string, args?: { [key: string]: any }) => string
) => {
  if (args && Object.keys(args).length > 0)
    return {
      ...args,
      ...Object.keys(args)
        .filter(y => typeof args[y] === 'string')
        .map(y => ({ [y]: mutatorFn(args[y]) }))
        .reduce((l, r) => ({ ...l, ...r }), {})
    };
  return args;
};

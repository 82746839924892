import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators, ɵFormGroupValue } from '@angular/forms';
import { coerceTimeFormat, timeFormatToDuration } from '@app/_helpers/utils';
import { parseDuration } from '@app/components/duration-input-control/duration-input-utils';
import { isSameMinute, isValid, parse, startOfDay } from 'date-fns/esm';
import { Observable, map } from 'rxjs';
import { Logger } from 'timeghost-api';
const log = new Logger('CustomValidators');
export const EMAIL_REGEX =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
const INVALID_RANGE_OBJ = { content: 'errors.record.time-invalid-range' };
const getControlName = (group: FormGroup, ctrl: AbstractControl) =>
  !group.controls ||
  Object.entries(group.controls).find(([key, gctrl]) => gctrl === ctrl)?.[0] ||
  'Unable to fetch control name';
export class CustomValidators {
  static isEmail(val: string) {
    return EMAIL_REGEX.test(val);
  }
  static isCharactersOrSpace = Validators.pattern('[a-zA-Z ]*');
  static isStaffId = Validators.pattern('[a-zA-Z0-9ß -/\\#,.]*');
  static hasNotNumber = (ctrl: AbstractControl) => {
    return `${ctrl.value}`.match(/[0-9]/g)?.length > 0 ? { hasNumber: true } : null;
  };

  static timeDiffCheck = (
    leftKey: string,
    rightKey: string,
    allowZero: boolean = true,
    getDateValueRef?: (key: string) => Date,
  ) =>
    ((): ValidatorFn => (ctrl) => {
      const value = ctrl.value;
      if (value[leftKey] == null || value[rightKey] == null) {
        return null;
      }
      const start = coerceTimeFormat(value[leftKey], getDateValueRef?.(leftKey) || new Date()),
        end = coerceTimeFormat(value[rightKey], getDateValueRef?.(rightKey) || new Date());
      if (!isValid(start) || !isValid(end)) {
        return {
          range: { start: value[leftKey], end: value[rightKey], invalidRange: true, ...INVALID_RANGE_OBJ },
        };
      }

      if (start.getTime() > end.getTime())
        return {
          range: { start: value[leftKey], end: value[rightKey], invalidRange: true, ...INVALID_RANGE_OBJ },
        };
      if (!allowZero && isSameMinute(start, end))
        return {
          same: true,
          fields: {
            field: leftKey,
            field2: rightKey,
          },
        } as SameValueError;

      return null;
    })();
  static validDateFormat =
    (format: string | 'duration' = 'HH:mm', required?: boolean) =>
    (ctrl: AbstractControl) => {
      if (!ctrl.value) {
        if (required)
          return {
            time: {
              value: ctrl.value,
            },
          };
        return null;
      }
      if (
        format === 'duration'
          ? typeof timeFormatToDuration(ctrl.value) !== 'number'
          : !isValid(parse(ctrl.value, format, new Date()))
      )
        return {
          time: {
            value: ctrl.value,
          },
        };
      return null;
    };
  static validDuration = (required?: boolean) => (ctrl: AbstractControl) => {
    if (!ctrl.value) {
      if (required)
        return {
          time: {
            value: ctrl.value,
          },
        };
      return null;
    }
    let pDuration: [number, number];
    if (!(pDuration = parseDuration(ctrl.value)) || pDuration.find((x) => x === undefined))
      return {
        duration: ctrl.value,
        durationMessage: 'Invalid Duration',
      };
    return null;
  };
  static minDuration = (ms: number) => (ctrl: AbstractControl) => {
    if (!ctrl.value) return null;
    let lastValue: Date;
    const controlName = getControlName(ctrl.root as any, ctrl);
    log.debug('minDuration:1', controlName, ctrl.value);
    if (
      isValid((lastValue = coerceTimeFormat(ctrl.value))) &&
      startOfDay(lastValue.getTime()).getTime() + ms >= lastValue.getTime()
    ) {
      log.debug('minDuration:2', controlName, ctrl.value);

      return {
        minDuration: {
          value: ctrl.value,
          min: ms,
        },
      };
    }
    return null;
  };
  static arrayMinLength = (minLength: number) => (ctrl: AbstractControl) => {
    if (!ctrl.value) return null;
    if (!Array.isArray(ctrl.value)) return null;
    if (ctrl.value.length >= minLength) return null;
    return {
      minLength: {
        minLength,
        length: ctrl.value.length,
      },
    };
  };
  static arrayMaxLength = (maxLength: number) => (ctrl: AbstractControl) => {
    if (!ctrl.value) return null;
    if (!Array.isArray(ctrl.value)) return null;
    if (ctrl.value.length <= maxLength) return null;
    return {
      maxLength: {
        maxLength,
        length: ctrl.value.length,
      },
    };
  };
  static controlErrorLog = (ctrl: AbstractControl): any => {
    if (ctrl instanceof FormGroup) log.debug('ctrl errors', ctrl.errors, ctrl.invalid);
    else log.debug((ctrl.root['controls'] && getControlName(ctrl.root as any, ctrl)) || 'Root', ctrl.errors);
    return null;
  };
  static match = (reg: RegExp) => (ctrl: AbstractControl) => {
    if (!reg.test(ctrl.value))
      return {
        match: ctrl.value,
      };
    return null;
  };
  static validDate = (ctrl: AbstractControl) => {
    if (!ctrl.value) return null;
    if (!isValid(ctrl.value))
      return {
        time: ctrl.value,
      };
    return null;
  };
  static minLength = (_minLength: number) => {
    return (ctrl: AbstractControl) => {
      if (!ctrl.value) return null;
      if (typeof ctrl.value !== 'string') {
        return {
          minlength: {
            requiredLength: _minLength,
            actualLength: 0,
          },
        };
      }

      let length = ctrl.value.trim().length;
      if (length >= _minLength) {
        return null;
      }
      return {
        minlength: {
          requiredLength: _minLength,
          actualLength: length,
        },
      };
    };
  };
  static mustChange = <T1 extends {} = any>(
    before: any,
    onlyDisabled: boolean = false,
    mapper?: <T extends ɵFormGroupValue<T1> = ɵFormGroupValue<T1>, R extends T = T>(value: T) => R,
  ) => {
    return (ctrl: AbstractControl) => {
      if (ctrl.disabled) return null;
      const left = {
        ...Object.keys(ctrl.value).reduce((l, r) => {
          return { ...l, [r]: before[r] };
        }, {}),
      };
      new Logger('mustChange').debug(Object.assign({}, { left, right: ctrl.value }));
      return JSON.stringify(left) !== JSON.stringify(mapper?.(ctrl.value) ?? ctrl.value)
        ? null
        : {
            nochange: true,
          };
    };
  };
  static controlMustChange = (before: any) => {
    return (ctrl: AbstractControl) => {
      if (ctrl.disabled) return null;
      return JSON.stringify(before) !== JSON.stringify(ctrl.value)
        ? null
        : {
            nochange: true,
          };
    };
  };
  static controlMustChangeWithMap = <T>(before: T, maps: { [K in keyof T]?: (obj: T[K]) => any }) => {
    return (ctrl: AbstractControl) => {
      if (ctrl.disabled) return null;
      return Object.keys(before).find((key) => {
        const mapper = maps?.[key];
        if (mapper) return JSON.stringify(mapper(before?.[key])) !== JSON.stringify(mapper(ctrl.value?.[key]));
        else return JSON.stringify(before?.[key]) !== JSON.stringify(ctrl.value?.[key]);
      })
        ? null
        : { nochange: true };
    };
  };
  static entityValidator = function <T = any>(
    userFn: () => T,
    val: (user: T, ctrl: AbstractControl) => any | null,
  ): ValidatorFn {
    const entity = userFn();
    return (ctrl) => {
      return val(entity, ctrl);
    };
  };
  static checkForChange = (val: (_ctrl: AbstractControl) => boolean) => {
    return (ctrl: AbstractControl) => {
      if (ctrl.disabled) return null;
      return !val(ctrl)
        ? null
        : {
            changed: true,
          };
    };
  };
  static typeCheck =
    (type: 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function') =>
    (cntrl: AbstractControl) => {
      if (typeof cntrl.value !== type) {
        return {
          typeCheck: {
            invalid: true,
            current: typeof cntrl.value,
            expected: type,
          },
        };
      }
      return null;
    };
  static isNumeric = (ctrl: AbstractControl) => {
    if (!ctrl.value) return null;
    if (typeof ctrl.value === 'number') return null;
    return Validators.pattern('^[0-9]*$');
  };
  static isFloat = (ctrl: AbstractControl) => {
    return Validators.pattern('^[0-9]+([,.][0-9]+)?$')(ctrl);
  };
  /**
   * @name predicate Make your own Validation
   * @param func Custom Function to validate stuff on your context (return object for custom validation errors otherwise use true/false)
   * @returns object | null
   */
  static predicate = (func: (ctrl: AbstractControl) => any) => (ctrl: AbstractControl) => {
    const val: string = ctrl.value;
    const result = func(ctrl);
    if (typeof result === 'boolean' && !!result) {
      return {
        predicate: false,
      };
    }
    if (typeof result === 'object' && !!result) {
      return result;
    }
    return null;
  };
  /**
   *
   * @param func function for determining if validators should be added
   * @param validators
   * @returns validation function with conditional validators
   */
  static conditional(
    func: Parameters<(typeof CustomValidators)['predicate']>[0],
    ...validators: ((ctrl: AbstractControl) => ValidationErrors)[]
  ) {
    return (ctrl: AbstractControl) => {
      const result = this.predicate(func);
      if (!result) return null;
      for (const validate of validators) {
        const vresult = validate(ctrl);
        if (vresult) return vresult;
      }
      return null;
    };
  }

  /**
   *
   * @param func function for determining if validators should be added
   * @param validators
   * @returns validation function with conditional validators
   */
  static conditionalWithUpdate(
    func: Parameters<(typeof CustomValidators)['predicate']>[0],
    ...validators: ((ctrl: AbstractControl) => ValidationErrors)[]
  ) {
    return (ctrl: AbstractControl) => {
      const result = this.predicate(func)?.(ctrl);
      if (!result) return null;
      for (const validate of validators) {
        const vresult = validate(ctrl);
        if (vresult) return vresult;
      }
      return null;
    };
  }
  /**
   *
   * @param func function for determining if validators should be added
   * @param check props on change
   * @param validators
   * @returns validation function with conditional validators
   */
  static conditionalWithUpdateWithProps(
    func: Parameters<(typeof CustomValidators)['predicate']>[0],
    propKeys: string[],
    ...validators: ((ctrl: AbstractControl) => ValidationErrors)[]
  ) {
    let observable: Observable<any>;
    let lastValue: any;
    return (ctrl: AbstractControl) => {
      const result = this.predicate(func)?.(ctrl);
      if (!result) return null;
      for (const validate of validators) {
        const vresult = validate(ctrl);
        if (vresult) return vresult;
      }
      if (!observable) {
        observable = ctrl.root.valueChanges.pipe(
          map((x) => {
            const nextValue = Object.entries(x).reduce((acc, [k, value]) => {
              if (propKeys.includes(k)) acc[k] = value;
              return acc;
            }, {});
            if (!lastValue || JSON.stringify(nextValue) !== JSON.stringify(lastValue))
              ctrl.updateValueAndValidity({ onlySelf: true });
            lastValue = nextValue;

            return nextValue;
          }),
        );
      }
      return null;
    };
  }
  /**
   *
   * @param check props on change
   * @returns validation function with recheck on props change
   */
  static recheckOnPropsChange(propKeys: string[]) {
    let observable: Observable<any>;
    let lastValue: any;
    return (ctrl: AbstractControl) => {
      if (!observable) {
        observable = ctrl.root.valueChanges.pipe(
          map((x) => {
            const nextValue = Object.entries(x).reduce((acc, [k, value]) => {
              if (propKeys.includes(k)) acc[k] = value;
              return acc;
            }, {});
            if (!lastValue || JSON.stringify(nextValue) !== JSON.stringify(lastValue))
              ctrl.updateValueAndValidity({ onlySelf: true });
            lastValue = nextValue;

            return nextValue;
          }),
        );
      }
      return null as any;
    };
  }
}
export const DistinctUntilCosmosChange = (left: any, right: any) => {
  if (left && right) {
    if (Array.isArray(left) && Array.isArray(right)) {
      return (
        JSON.stringify(left.map(({ id, _ts }) => ({ id, _ts }))) ===
        JSON.stringify(right.map(({ id, _ts }) => ({ id, _ts })))
      );
    } else if (left._ts && right._ts) {
      return JSON.stringify(left, ['id', '_ts']) === JSON.stringify(right, ['id', '_ts']);
    }
  } else {
    return JSON.stringify(left) === JSON.stringify(right);
  }
};
export const DistinctUntilChangedBy =
  <T>(func: (item: T) => any) =>
  (left: T, right: T) =>
    func(left) === func(right);
export const DistinctUntilDiff = <T>(left: T, right: T) => left === right;

export interface SameValueError {
  fields: { field: string; field2: string };
  same: boolean;
}

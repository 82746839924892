import { createClientProjectSearch, createProjectSearch } from '@app/_helpers/search';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client, Project, Task } from 'timeghost-api';

export function projectSearch(data: Project[], advSearch: string[]) {
  return data
    .filter(
      (y) =>
        advSearch.filter((s) => y.name?.toLowerCase().indexOf(s) >= 0).length > 0 ||
        advSearch.filter((s) => y.client.name?.toLowerCase().indexOf(s) >= 0).length > 0
    )
    .sort(
      (a, b) =>
        advSearch.filter((s) => b.name?.toLowerCase().indexOf(s) >= 0).length -
        advSearch.filter((s) => a.name?.toLowerCase().indexOf(s) >= 0).length
    );
}
export class ClientProjectModel {
  public projects: Project[];
  public client: Client;
  public id: number;

  constructor(data: Partial<ClientProjectModel>) {
    Object.assign(this, data);
  }
  static search(
    data: ClientProjectModel[],
    search: string,
    translators: { [key: string]: (entity: { id: string; name: string }) => string }
  ) {
    const advSearch = search
      .toLowerCase()
      .split(' ')
      .filter((s) => !!s);
    return data
      .filter((x) => {
        const clientName = translators.client?.(x.client) || x.client.name;
        return (
          advSearch.filter((s) => clientName?.toLowerCase().indexOf(s) >= 0).length > 0 ||
          x.projects.findIndex((y) => {
            const projectName = translators.project?.(y) || y.name;
            return advSearch.filter((s) => projectName?.toLowerCase().indexOf(s) >= 0).length > 0;
          }) !== -1
        );
      })
      .map((x) => {
        const clientName = translators.client?.(x.client) || x.client.name;
        return {
          ...x,
          projects: x.projects
            .filter((y) => {
              const projectName = translators.project?.(y) || y.name;
              return (
                advSearch.filter((s) => projectName?.toLowerCase().indexOf(s) >= 0).length > 0 ||
                advSearch.filter((s) => clientName?.toLowerCase().indexOf(s) >= 0).length > 0
              );
            })
            .sort(
              (a, b) =>
                advSearch.filter((s) => b.name?.toLowerCase().indexOf(s) >= 0).length -
                advSearch.filter((s) => a.name?.toLowerCase().indexOf(s) >= 0).length
            ),
        };
      })
      .filter((x) => x.projects.length > 0)
      .sort((a, b) => b.projects.length - a.projects.length);
  }
}
export class ClientProjectTimeModel {
  public projects: ProjectTimeModel[];
  public client: Client;
  public id: number;

  constructor(data: Partial<ClientProjectTimeModel>) {
    Object.assign(this, data);
  }
}
export class ProjectTimeModel {
  public project: Project;
  public tasks: Task[];
  constructor(data: Partial<ProjectTimeModel>) {
    Object.assign(this, data);
  }
}
export const searchProjects = (
  search: string,
  translateKeys: { [key: string]: (data: Project) => string }
): OperatorFunction<Project[], Project[]> => (source: Observable<Project[]>) => {
  return source.pipe(
    map((data: Project[]) => {
      if (!search || search?.trim().length < 2) return data;
      return createClientProjectSearch(data, { translateKeys })
        .search(search)
        .map((x) => ({ ...x.project, client: x.client }));
    })
  );
};

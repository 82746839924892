import FlexSearch from 'flexsearch';
import Fuse from 'fuse.js';
import { firstBy } from 'thenby';
import { Client, Logger, Project, Task } from 'timeghost-api';
const convertSearch = (input: string): [string, string[], string] => {
  return [input.toLowerCase(), input.split(' ').filter((x) => !!x), input];
};
interface ProjectClientBag {
  /**
   *
   * project id
   * @type {string}
   * @memberof ProjectClientBag
   */
  id: string;
  name?: string;
  project: Project;
  client: Client;
  score?: number;
}
export const projectFinder = (_search: string, data: Project[]) => {
  const [search] = convertSearch(_search);
  return data.filter(
    (x) => x.name.toLowerCase().indexOf(search) !== -1 || x.client.name.toLowerCase().indexOf(search) !== -1,
  );
};
const log = new Logger('EntityFlexSearch');
class EntityFlexSearch<T extends { id: any }[]> {
  constructor(
    private doc: FlexSearch.Document<any>,
    private data: T,
  ) {}
  search(str: string, limit?: number): T {
    return this.doc
      .search(str, limit)
      .reduce((l, r) => {
        const ids = r.result.filter((x) => !l.includes(x));
        if (ids?.length) l.push(...ids);
        return l;
      }, [] as any[])
      .map((f) => this.data.find((y) => y.id === f)) as any;
  }
}
class EntityFuseSearch<T extends ProjectClientBag[] = ProjectClientBag[]> {
  constructor(
    private fuse: Fuse<T[0]>,
    private data: T,
    private options?: Partial<{ ignoreSelected: boolean; useExtendedSearch: boolean }>,
  ) {
    this.fuse.setCollection(data);
  }
  search(str: string, limit?: number): T {
    if (this.options?.useExtendedSearch) str = '^' + str;
    let searchResults = this.fuse
      .search(str, { limit })
      .map((x) => ({ id: x.item.id, project: x.item.project, client: x.item.client, score: x.score })) as T;
    log.debug(searchResults);
    let viewSelected: T[0];
    if (
      this.options?.ignoreSelected &&
      !searchResults.find((x) => x.project['selected']) &&
      !!(viewSelected = this.data.find((x) => x.project['selected']))
    ) {
      searchResults = [viewSelected, ...searchResults.sort(firstBy((x) => x.score, 'desc'))] as T;
    }
    return searchResults;
  }
}
export const createClientProjectSearch = (
  data: Project[],
  { translateKeys }: { translateKeys?: { [key: string]: (entity: { id: string; name: string }) => string } } = {},
) => {
  const fuseIndex: Partial<Fuse.FuseOptionKeyObject<ProjectClientBag>>[] = [
    {
      name: 'project.name',
      weight: 0.5,
    },
    {
      name: 'client.name',
      weight: 0.5,
    },
    {
      name: 'project.externalId',
      weight: 0.5,
    },
    {
      name: 'project.description',
      weight: 0.3,
    },
  ];
  const useExtendedSearch = false;
  const fuse = new Fuse([], {
    keys: fuseIndex.map((x: Fuse.FuseOptionKeyObject<ProjectClientBag>) => ({ ...x, weight: x.weight ?? 1 })),
    getFn: (data: ProjectClientBag, _key: string[]) => {
      const key = _key.join('.');
      return (
        translateKeys?.[key]?.(
          (key === 'project.name' ? data.project : key === 'client.name' ? data.client : data) as any,
        ) ?? Fuse.config.getFn(data, _key)
      );
    },
    shouldSort: true,
    includeScore: true,
    includeMatches: false,
    isCaseSensitive: false,
    findAllMatches: false,
    ignoreLocation: true,
    ignoreFieldNorm: false,
    minMatchCharLength: 2,
    threshold: 0.1,
    useExtendedSearch,
  });
  const newData = data.map(
    (x): ProjectClientBag => ({ id: x.id, name: x.name, project: x, client: x.client as Client }),
  );
  return new EntityFuseSearch<ProjectClientBag[]>(fuse, newData, { ignoreSelected: true, useExtendedSearch });
};
export const createProjectSearch = (data: Project[]) => {
  const doc = new FlexSearch.Document({
    document: {
      id: 'id',
      index: [
        { field: 'client', tokenize: 'forward' },
        { field: 'project', tokenize: 'forward' },
      ],
    },
  });
  data.forEach((x) => doc.add({ id: x.id, client: x.client?.name, project: x.name }));
  return new EntityFlexSearch<Project[]>(doc, data);
};
export const createClientSearch = (data: Client[]) => {
  const doc = new FlexSearch.Document({
    document: {
      id: 'id',
      index: [{ field: 'client', tokenize: 'forward' }],
    },
  });
  data.forEach((x) => doc.add({ id: x.id, client: x.name }));
  return new EntityFlexSearch<Client[]>(doc, data);
};
export const createTaskSearch = (data: Task[]) => {
  const doc = new FlexSearch.Document({
    document: {
      id: 'id',
      index: [{ field: 'name', tokenize: 'forward' }],
    },
  });
  data.forEach(({ id, name }) => doc.add({ id, name }));
  return new EntityFlexSearch<typeof data>(doc, data);
};

import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '@app/_helpers/utils';

@Pipe({
  name: 'clsx',
  standalone: true,
})
export class ClsxPipe implements PipeTransform {
  transform(arg: string | (string | number | null | undefined | false)[]): string[] {
    const value = !arg ? null : Array.isArray(arg) ? arg : [arg];
    if (!value) return null;
    return value.reduce((acc, c) => {
      if (isNullOrUndefined(c) || !c) return acc;
      const classes = String(c)?.split?.(' ');
      if (classes?.length) {
        acc.push(...classes.filter((x) => x && !acc.find((d) => d === x)));
      }
      return acc;
    }, [] as string[]);
  }
}

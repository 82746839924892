import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { distinctUntilChangedJson } from '@app/_helpers/utils';
import { flow } from 'lodash-es';
import { combineLatest, map, startWith } from 'rxjs';
import { Client, Project, ProjectsQuery } from 'timeghost-api';

@Component({
  selector: 'tg-project-template-dialog',
  templateUrl: './project-template-dialog.component.html',
  styleUrls: ['./project-template-dialog.component.scss'],
})
export class ProjectTemplateDialogComponent implements OnInit {
  readonly group = new FormGroup({
    client: new FormControl<Client>(null),
    project: new FormControl<Project>(this.data.selected),
    search: new FormControl<string>(''),
  });
  constructor(
    private ref: MatDialogRef<ProjectTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private projectsQuery: ProjectsQuery,
  ) {}

  ngOnInit(): void {
    this.ref._containerInstance._config.autoFocus = true;
    this.ref._containerInstance._config.closeOnNavigation = true;
    this.ref.addPanelClass('client-project-picker-dialog-container');
    this.ref.updateSize('460px');
  }
  readonly data$ = this.projectsQuery.selectAll({ filterBy: (x) => x.projectType === 'template' });
  readonly data$filtered = combineLatest([
    this.data$,
    this.group.valueChanges.pipe(startWith(this.group.value), distinctUntilChangedJson()),
  ]).pipe(
    debounceTimeAfterFirst(500),
    map(([data, { client, project, search }]) => {
      const q = search?.toLowerCase();
      return flow(
        (d: Project[]) => (q?.length > 0 ? d.filter((y) => y.name.toLowerCase().indexOf(q) !== -1) : d),
        (d) => d.map((x) => ({ ...x, selected: project?.id === x.id })),
      )(data);
    }),
  );
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
  setProject(project: Project, close: boolean = true) {
    if (project.id === this.group.value.project?.id) return;
    this.group.patchValue({
      project,
    });
    if (close) return this.ref.close(this.group.value?.project);
  }
}

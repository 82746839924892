<div class="loader-container">
  <app-loader [isActive]="this.isLoading" [enableCircle]="true" [width]="20"></app-loader>
  <h1 matDialogTitle>
    <div class="flex flex-row items-center justify-start">
      <div>{{ 'project.create' | translate }}</div>
      <div class="flex-auto"></div>
      <button mat-icon-button matDialogClose tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </h1>
  <mat-dialog-content>
    <form
      [formGroup]="this.projectForm"
      (onsubmit)="this.submit()"
      class="flex flex-col items-stretch justify-start gap-3"
    >
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>Name</mat-label>
        <input type="text" formControlName="name" matInput required cdkInitialFocus />
        <div
          [style.backgroundColor]="color"
          [cpPosition]="'bottom'"
          [cpPositionOffset]="'24px'"
          class="project-color-dot color-picker-material"
          [matTooltip]="'project.color' | translate"
          matPrefix
          [colorPicker]="projectForm.value.color"
          (colorPickerChange)="projectForm.patchValue({ color: $event })"
        ></div>
        <mat-error *ngIf="this.formNameErrors as error">{{ error.content | translate: error.args || {} }}</mat-error>
      </mat-form-field>
      <div class="flex flex-col space-y-0 pb-4">
        <mat-form-field class="w-full" appearance="fill" (click)="$event.preventDefault(); openTemplatePicker()">
          <mat-label>{{ 'template' | translate }}</mat-label>
          <input class="cursor-pointer" type="text" matInput readonly [value]="projectForm.value.template?.name" />
          <button
            *ngIf="projectForm.value.template"
            mat-icon-button
            matSuffix
            type="button"
            tabindex="-1"
            (click.prevent)="projectForm.patchValue({ template: null })"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
        <div
          class="rounded-bottom flex flex-col space-y-2 bg-neutral-500/10 px-3.5 pb-4 pt-2 dark:bg-black/10"
          *ngIf="projectForm.value.template"
        >
          <div class="flex">
            <mat-checkbox formControlName="templateCopyRate" color="primary">{{
              'project.template.rateCopy' | translate
            }}</mat-checkbox>
          </div>
          <div class="flex">
            <mat-checkbox formControlName="templateCopyTasks" color="primary">{{
              'project.template.tasksCopy' | translate
            }}</mat-checkbox>
          </div>
          <div class="flex">
            <mat-checkbox
              formControlName="templateCopyTaskAssign"
              color="primary"
              [disabled]="!projectForm.value.templateCopyTasks"
              >{{ 'project.template.tasksCopyAssign' | translate }}</mat-checkbox
            >
          </div>
        </div>
      </div>
      <mat-form-field class="w-full" appearance="fill" (click)="$event.preventDefault(); this.openClientPicker()">
        <mat-label>{{ 'Client' | translate }}</mat-label>
        <input
          class="cursor-pointer"
          type="text"
          matInput
          readonly
          [value]="projectForm.value.client | defaultEntityTranslate: 'client' : 'client.none'"
        />
        <button
          *ngIf="projectForm.value.client && !projectForm.value.client.useAsDefault"
          mat-icon-button
          matSuffix
          type="button"
          tabindex="-1"
          (click.native)="this.setClient()"
        >
          <mat-icon>clear</mat-icon>
        </button>
        <mat-error *ngIf="!projectForm.value.client"
          >{{ 'errors.required' | translate: { field: 'Client' | translate } }} *</mat-error
        >
      </mat-form-field>
      @if (showAdvancedSettings) {
        <mat-accordion
          class="mat-elevation-z0 mat-dialog-content-fluid mb-3"
          displayMode="flat"
          [multi]="true"
          [hideToggle]="false"
        >
          <mat-expansion-panel class="mat-elevation-z0" [hideToggle]="false" [(expanded)]="showAdvanced">
            <mat-expansion-panel-header>{{ 'settings.additional' | translate }}</mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="flex flex-col items-stretch justify-start">
                <mat-form-field appearance="fill">
                  <mat-label class="mat-typography">{{ 'filter-toolbar.billable-filter' | translate }}</mat-label>
                  <mat-select formControlName="billing">
                    <mat-option [value]="true">{{ 'filter-toolbar.projects.billable-on' | translate }} </mat-option>
                    <mat-option [value]="false">{{ 'filter-toolbar.projects.billable-off' | translate }} </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'project.privateToggle' | translate }}</mat-label>
                  <mat-select formControlName="private">
                    <mat-option [value]="true">
                      {{ 'project.private.state' | translate }}
                    </mat-option>
                    <mat-option [value]="false">
                      {{ 'project.public.state' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'project.budget._' | translate }}</mat-label>
                  <mat-select formControlName="estimationType">
                    <mat-option value="task">{{ 'project.budget.task-option' | translate }}</mat-option>
                    <mat-option value="manual">{{ 'project.budget.manual-option' | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container *ngIf="this.projectForm?.value?.estimationType === 'manual'">
                  <tg-duration-input-control
                    [inputPlaceholder]="'timer.time.duration' | translate"
                    formControlName="estimation"
                  >
                    <ng-template durationInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
                      <mat-form-field fxFill appearance="fill">
                        <mat-label>{{ 'project.budget.manual-input' | translate }}</mat-label>
                        <input type="text" matInput [formControl]="control" autocomplete="off" />
                      </mat-form-field>
                    </ng-template>
                  </tg-duration-input-control>
                </ng-container>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      }
    </form>
  </mat-dialog-content>
  <mat-dialog-actions
    class="mat-action-filled"
    [matTooltip]="'workspace.errors.admin-create-project' | translate"
    [matTooltipDisabled]="this.workspace$createPermission | async"
  >
    <button
      mat-flat-button
      class="mat-action-filled"
      color="primary"
      (click)="this.submit()"
      [disabled]="this.projectForm.invalid || !(this.workspace$createPermission | async)"
    >
      {{ 'project.create' | translate }}
    </button>
  </mat-dialog-actions>
</div>
<ng-template #createBlocked>
  <mat-dialog-actions class="m-0" align="center">
    <p class="text-warn m-0 text-center" translate>workspace.errors.admin-create-project</p>
  </mat-dialog-actions>
</ng-template>

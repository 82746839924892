import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { IdObject } from '@app/_classes/cosmos-entity';
import { isAdmin, isSupervisor } from '@app/_helpers/permission';
import { createRxValue, distinctUntilChangedJson } from '@app/_helpers/utils';
import startWithDefault from '@app/_operators/startWithDefault';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSettingsQuery, Workspace } from 'timeghost-api';
type User = Workspace['users'][0];
type UserSelectEntity = User & { selected?: boolean; subordinate?: boolean };
export interface UserSinglePickerDialogData {
  selected: User;
  filter?(user: User & { selected?: boolean }): boolean;
}
@Component({
  selector: 'tg-user-single-picker-dialog',
  templateUrl: './user-single-picker-dialog.component.html',
  styleUrls: ['./user-single-picker-dialog.component.scss'],
})
export class UserSinglePickerDialogComponent implements OnInit {
  readonly search = new UntypedFormControl('');
  readonly selected = createRxValue<User>(this.data?.selected);
  readonly data$filtered = combineLatest([
    this.search.valueChanges.pipe(startWithDefault()),
    this.userSettingsQuery.select(),
    this.selected.asObservable(),
  ]).pipe(
    distinctUntilChangedJson(),
    map(([q, user, selected]) => {
      const users = user.workspace?.users ?? [];
      const permAdmin = isAdmin(user);
      const permSupervisor = isSupervisor(user);
      let newUsers: UserSelectEntity[] = (() => {
        if (!q) return users;
        return users.filter(({ name, email }) =>
          [name, email].some((x) => x.toLowerCase().indexOf(q.toLowerCase()) !== -1),
        ) as any;
      })();
      newUsers = newUsers.map((x) => ({
        ...x,
        selected: !!selected && x.id === selected.id,
        subordinate: !!user.workspace.users?.find(
          (d) => d.id === x.id && d['supervisors']?.find((u: IdObject) => u.id === user.id),
        ),
      }));
      if (this.data.filter) newUsers = newUsers.filter((d) => this.data.filter(d) || d.selected);
      if (!permAdmin && permSupervisor)
        newUsers = newUsers.filter((d) => d.selected || d.id === user.id || d.subordinate);
      else if (!permAdmin && !permSupervisor) newUsers = newUsers.filter((d) => d.selected || d.id === user.id);
      return newUsers;
    }),
  );
  constructor(
    private ref: MatDialogRef<UserSinglePickerDialogComponent>,
    private userSettingsQuery: UserSettingsQuery,
    @Inject(MAT_DIALOG_DATA) private data: UserSinglePickerDialogData,
  ) {}

  ngOnInit(): void {
    this.ref.addPanelClass('user-single-picker-container');
    this.ref.updateSize('460px');
  }
  selectItem(user: User) {
    this.selected.value = user;
    return this.ref.close(user);
  }
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
}

import { isTeamsWindow } from '@env/msal';
import { isAfter, isValid } from 'date-fns/esm';
import { UserSettings, Workspace } from 'timeghost-api';
import { hasPermission } from './utils';

export default function parseSubscriptionAsStatus(x: Workspace, user: UserSettings) {
  if (!x) return null;
  const subscription = x.subscription;
  const isTrial = ['trial', 'trialing', 'in_trial'].includes(subscription.status);
  let endDate = subscription.end ? new Date(subscription.end) : null;
  if (endDate && endDate.getFullYear() === 1970) {
    endDate = new Date(endDate.getTime() * 1000);
  }
  if (!endDate && subscription.status === 'paused') {
    endDate = new Date();
  }
  const activeUsers = ((us) => (isTrial ? us : us.slice(0, subscription.quantity)))(
    x.users.filter((x) => !x.removed && x.has_license),
  );
  const currentUser = activeUsers.find((u) => u.id === user.id);
  const userIsActive = isTrial ? true : !!activeUsers.find((u) => u.id === user.id);
  const userIsAdmin = userIsActive && !!currentUser?.admin;
  const testTeams = window['teams_test'],
    testTeamsMobile = window['teams_test'] === 'mobile';
  const ret = {
    workspaceId: x.id,
    status: subscription.status,
    permission: x.permissionSettings,
    hasPermission: (id: string) => hasPermission(id, user),
    isChargebee: subscription['provider'] === 'chargebee',
    isPaddle: subscription['provider'] === 'paddle',
    isMicrosoft: (subscription['provider'] as any) === 'microsoft_fulfillment',
    isExternal: ['microsoft_fulfillment'].includes(subscription['provider']),
    subscription,
    isUserSlotsExhausted: isTrial ? false : activeUsers.length >= subscription.quantity,
    isCurrentUserInSlotRange: userIsActive,
    isOwner: x.users.findIndex((y) => y.id === user.id && y.admin) !== -1,
    isTrial,
    isTeams: isTeamsWindow() || testTeams,
    isTeamsMobile: !!window.teamsMobile || testTeamsMobile,
    isAborted: ['deleted', 'non_renewing', 'cancelled'].includes(subscription.status),
    isDeleted: ['deleted', 'cancelled'].includes(subscription.status),
    isPaused: ['paused', 'past_due'].includes(subscription.status),
    isExpired: subscription.status === 'active' ? false : !subscription.end || isAfter(new Date(), endDate),
    endDate: isValid(endDate) ? endDate : null,
    activeUsers,
    canEdit: !!x.currentUserCanEdit,
  };
  return {
    ...ret,
    canUpgrade: userIsAdmin && (ret.isTrial || ret.isExpired || ret.isDeleted),
    canUpgradeQuantity: userIsAdmin && !ret.isExpired,
    userIsAdmin,
  };
}

<ng-container *ngIf="this.isActiveAsync | async as isActive">
  <div
    *ngIf="!!isActive"
    class="app-loader overflow-hidden {{ this.classList }}"
    @fadeOut
    [style.position]="this.fixed ? 'fixed' : 'absolute'"
    [style.backgroundColor]="this.customBackColor"
  >
    <div class="loader-wrapper">
      <div class="loader-content" @fade>
        <div class="loader-circle">
          <div *ngIf="this.logo.enabled">
            <div class="loader-logo" [style.width.px]="this.logo.width" [style.height.px]="this.logo.height">
              <img
                class="img-responsive"
                [border]="0"
                [ngClass]="{ 'rounded-circle': this.logo.circle, rounded: this.logo.rounded }"
                [style.width.px]="this.logo.width"
                [style.height.px]="this.logo.height"
                [src]="this.logo.url | safeUrl"
                #image
                alt=""
              />
            </div>
          </div>
          <ng-container *ngIf="this.enableCircle !== false">
            <mat-spinner
              [mode]="'indeterminate'"
              [strokeWidth]="this.circleStrokeWidth"
              [color]="this.customLoadingColor"
              [diameter]="this.width"
            ></mat-spinner>
          </ng-container>
          <ng-container *ngIf="this.enableCircle === false">
            <mat-progress-bar
              [mode]="'indeterminate'"
              [style.width.px]="this.width"
              [color]="this.customLoadingColor"
            ></mat-progress-bar>
          </ng-container>
          <div
            *ngIf="this.enableStatus"
            class="text-center mt-3"
            style="width: 200px; max-width: 100%"
            [innerText]="this.currentStatus"
          ></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ObserveFormGroupErrors, useFormErrorObservable } from '@app/_helpers/get-error-observable';
import { userProjectTaskPerm } from '@app/_helpers/userProjectFind';
import { createRxValue, hasUserInProject } from '@app/_helpers/utils';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger, Project, ProjectsQuery, TasksService, UserSettings, UserSettingsQuery } from 'timeghost-api';
const log = new Logger('CreateTaskDialogComponent');
export type CreateTaskDialogData = { search?: string; project?: Project };
@ObserveFormGroupErrors()
@UntilDestroy()
@Component({
  selector: 'tg-create-task-dialog',
  templateUrl: './create-task-dialog.component.html',
  styleUrls: ['./create-task-dialog.component.scss'],
})
export class CreateTaskDialogComponent implements OnInit {
  taskGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    project: new FormControl<Project>(null),
    estimation: new UntypedFormControl(null),
    assigned: new FormControl({ users: [], groups: [] }),
  });
  isLoading = createRxValue(false);
  constructor(
    private ref: MatDialogRef<CreateTaskDialogComponent>,
    private projectQuery: ProjectsQuery,
    private userSettingsQuery: UserSettingsQuery,
    private taskService: TasksService,
    private recordService: RecordToolbarService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    private data: Partial<CreateTaskDialogData>,
  ) {}

  ngOnInit(): void {
    this.ref.updateSize('500px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    const project: Project = this.data.project ?? this.defaultProject;
    this.taskGroup.patchValue({
      project,
      name: this.data.search || '',
    });
    this.taskGroup.updateValueAndValidity();
  }
  selectedUsers$ = this.taskGroup.valueChanges.pipe(map((x) => x.assigned?.users));
  get formNameErrors() {
    if (this.taskGroup.controls.name.errors?.required) return { content: 'errors.required', args: { field: 'Name' } };
    if (this.taskGroup.controls.name.errors?.minlength)
      return { content: 'errors.minlength', args: { field: 'Name', length: 3 } };
    if (this.taskGroup.controls.name.errors?.exists) return { content: 'errors.unique', args: { field: 'Name' } };
    return null;
  }
  getFormErrorByName(controlName: string) {
    return useFormErrorObservable(this)(
      controlName,
      () => this.taskGroup.controls[controlName],
      {
        exists: (error, ctrl) => ({ content: 'errors.unique', args: { field: `Name` } }),
        required: (error, ctrl) => ({
          content: 'errors.required',
          args: { field: `Name` },
        }),
        minlength: (error, ctrl) => ({
          content: 'errors.minlength',
          args: { field: `Name` },
        }),
      },
      (key) => this.translateService.instant(key),
    );
  }
  trackId(_: number, { id }: any) {
    return id;
  }
  get defaultProject() {
    return this.projectQuery.getAll({ filterBy: (x) => x.useAsDefault })?.[0];
  }
  openProjectPicker() {
    // const project = this.taskGroup.value.project;
    // return this.dialog
    //   .open(projectscomponent, {
    //     data: <ClientProjectDialogData>{
    //       data: {
    //         selectedProject: this.group.value.project,
    //         selectedTask: this.group.value.task,
    //         closeOnRemove: true,
    //         canToggle: true,
    //         defaultProject: this.defaultProject,
    //       },
    //     },
    //   })
    //   .afterClosed()
    //   .pipe(filter((x) => !!x))
    //   .subscribe(([x, t]: [Project, Task]) => {
    //     const newProject = x ?? this.defaultProject;
    //     this.group.patchValue({
    //       project: newProject
    //     });
    //   });
  }
  readonly project$createPermission = combineLatest([
    this.userSettingsQuery.select(),
    this.taskGroup.valueChanges,
    this.isLoading.value$,
  ]).pipe(
    map(([user, { project }, isLoading]: [UserSettings, { project: Project }, boolean]) => {
      log.debug(project, this.taskGroup.status, isLoading);
      const isValid = this.taskGroup.valid;
      if (user?.workspace.users.find((x) => x.id === user.id && x.admin) && isValid) return true;
      if (isLoading || !project || !isValid) return false;
      return userProjectTaskPerm(user, project);
    }),
  );

  filterProjectExclusive = (({ id, entity, type }: { id: string; entity: any; type: 'user' | 'group' }) => {
    if (!this.taskGroup.value.project?.private) return true;
    const user = this.userSettingsQuery.getValue();
    const project: Project = this.taskGroup.value.project;
    if (type === 'group') return !!project.groups?.find((x) => !x.removed && x.id === id);
    else if (type === 'user') return hasUserInProject({ ...user, id }, project);

    return true;
  }).bind(this);
  createTask() {
    if (!this.taskGroup.valid) return;
    const value = this.taskGroup.getRawValue();
    this.isLoading.value = true;
    return this.taskService
      .add({
        name: value.name,
        estimation: value.estimation
          ? (([hours, minutes]) => {
              return (hours * 60 + minutes) * 60;
            })(value.estimation.split(':').map(Number))
          : value.estimation,
        project: value.project,
        assignedToGroups: value.assigned?.groups,
        assignedToUsers: value.assigned?.users,
      })
      .toPromise()
      .then((task) => {
        this.ref.close(task);
      })
      .catch((err) => {
        this.isLoading.value = false;
        this.recordService.handleError(err);
      });
  }
}

import { AfterContentInit, Component, Inject, OnInit, ViewChild, signal } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { hasPermission } from '@app/_helpers/utils';
import { filter } from 'rxjs';
import { Project, Task, UserSettingsQuery } from 'timeghost-api';
import { CreateProjectComponent } from '../dialogs/create-project/create-project.component';
import { PlainStepperComponent } from '../plain-stepper/plain-stepper.component';
import { TimeTrackCreateData } from '../time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.component';

@Component({
  selector: 'tg-time-tracker-calendar-stepper-create-dialog',
  templateUrl: './time-tracker-calendar-stepper-create-dialog.component.html',
  styleUrls: ['./time-tracker-calendar-stepper-create-dialog.component.scss'],
  animations: [],
})
export class TimeTrackerCalendarStepperCreateDialogComponent implements OnInit, AfterContentInit {
  @ViewChild(PlainStepperComponent, { static: true })
  stepper: PlainStepperComponent;
  constructor(
    private ref: MatDialogRef<TimeTrackerCalendarStepperCreateDialogComponent>,
    private userSettingsQuery: UserSettingsQuery,
    @Inject(MAT_DIALOG_DATA)
    public createData: TimeTrackCreateData & { skipProjectStepper: boolean },
    private dialog: MatDialog,
  ) {}

  stepIndex = signal(0);
  readonly project$canManage = this.userSettingsQuery.select(
    (x) => !!x.workspace.permissionSettings.groupsCanManageProjects.find((y) => hasPermission(y.id, x)),
  );
  ngOnInit() {
    this.ref.updateSize('760px', '620px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    if (this.createData?.skipProjectStepper === true) this.stepIndex.set(1);
  }
  next() {
    this.stepper.next();
  }
  get entityData() {
    return {
      project: this.createData?.project,
      task: this.createData?.task,
    };
  }
  onSelectProject(entity: { project: Project; task?: Task }) {
    if (entity)
      this.createData = {
        ...this.createData,
        project: entity.project,
        billable: !!entity.project?.billable,
        task: entity.task,
      };
    this.stepIndex.set(1);
  }
  ngAfterContentInit() {
    setTimeout(() => {
      if (this.createData?.skipProjectStepper !== true) this.stepIndex.set(0);
    }, 50);
  }
  createProject() {
    this.dialog
      .open(CreateProjectComponent, {
        data: {
          type: 'project',
        },
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((x: Project) => {
        this.onSelectProject({ project: x, task: null });
      });
  }
}
